export const DSL_ZIP_ERROR_EMPTY = 'Bitte geben Sie Ihre Postleitzahl ein.';
export const DSL_ZIP_ERROR_WRONG_FORMAT = 'Ihre Postleitzahl muss aus 5 Ziffern bestehen.';
export const DSL_ZIP_INVALID = 'Diese Postleitzahl ist ungültig.';
export const DSL_VODAFONE_VOUCHER_INVALID = 'Der eingegebene Aktionscode ist ungültig.';
export const DSL_CITY_ERROR_EMPTY = 'Bitte geben Sie Ihre Stadt ein.';
export const DSL_STREET_ERROR_EMPTY = 'Bitte geben Sie Ihre Straße ein.';
export const DSL_STREET_NUMBER_ERROR_EMPTY = 'Bitte geben Sie Ihre Hausnummer ein.';
export const DSL_VODAFONE_VOUCHER_ERROR_EMPTY = 'Bitte geben Sie einen Aktionscode ein.';
export const DSL_VODAFONE_VOUCHER_ERROR_LENGTH = 'Der Aktionscode muss aus 9 - 10 Zeichen bestehen.';
export const DSL_CONSUMPTION_ERROR_EMPTY = 'Bitte geben Sie Ihren Verbrauch ein.';
export const DSL_CONSUMPTION_ERROR_WRONG_FORMAT = 'Bitte geben Sie einen gültigen Verbrauch ein.';
export const DSL_RESULT_SUCCESS = 'DSL_RESULT_SUCCESS';
export const DSL_RESULT_ERROR = 'DSL_RESULT_ERROR';
export const DSL_VODAFONE_VOUCHER_RESULT_ERROR = 'DSL_VODAFONE_VOUCHER_RESULT_ERROR';
export const DSL_VODAFONE_VOUCHER_EMPTY_ERROR = 'DSL_VODAFONE_VOUCHER_EMPTY_ERROR';
export const DSL_RESULT_EMPTY = 'DSL_RESULT_EMPTY';
export const DSL_RESULT_PROVIDER_EMPTY = 'DSL_RESULT_PROVIDER_EMPTY';
