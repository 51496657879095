import {
  ADDRESS_STEP,
  DELIVERY_STEP,
  PAYMENT_STEP,
  PRIVACY_STEP,
  EECC_STEP
} from './constants';

const emptyStringValue = '';

export const energyInitialState = {
  modalType: emptyStringValue,
  modalProps: null,
  ident: 'webid',
  energyLogic: {
    activeStepName: ADDRESS_STEP,
    isEditMode: false,
    steps: [
      {
        type: ADDRESS_STEP,
        title: 'Lieferadresse / Rechnungsadresse',
        isValid: false
      },
      {
        type: DELIVERY_STEP,
        title: 'Altanbieter / Liefertermin',
        isValid: false
      },
      {
        type: PAYMENT_STEP,
        title: 'Bezahlung',
        isValid: false
      },
      {
        type: PRIVACY_STEP,
        title: 'Datenschutz & AGB',
        isValid: false
      },
      {
        type: EECC_STEP,
        title: 'Bestellzusammenfassung',
        isValid: false
      }
    ]
  },
  energyData: {
    addressValidated: true,
    salutation: emptyStringValue,
    firstName: emptyStringValue,
    lastName: emptyStringValue,
    birthDate: emptyStringValue,
    email: emptyStringValue,
    email2: emptyStringValue,
    areaCode: emptyStringValue,
    phoneNumber: emptyStringValue,
    street: emptyStringValue,
    streetNumber: emptyStringValue,
    adressExtra: emptyStringValue,
    zip: emptyStringValue,
    town: emptyStringValue,
    isDifferentDeliveryAddress: false,
    deliverySalutation: emptyStringValue,
    deliveryFirstName: emptyStringValue,
    deliveryLastName: emptyStringValue,
    deliveryStreet: emptyStringValue,
    deliveryStreetNumber: emptyStringValue,
    deliveryAddressExtra: emptyStringValue,
    deliveryZip: emptyStringValue,
    deliveryTown: emptyStringValue,
    deliveryType: emptyStringValue,
    desiredDeliveryDate: emptyStringValue,
    meterNumber: emptyStringValue,
    marketLocation: emptyStringValue,
    oldCustomerNumber: emptyStringValue,
    oldProvider: emptyStringValue,
    oldProviderCancelled: false,
    oldProviderCancelDate: emptyStringValue,
    isDifferentAccountHolder: false,
    accountHolderSalutation: emptyStringValue,
    accountHolderFirstName: emptyStringValue,
    accountHolderLastName: emptyStringValue,
    accountHolderStreet: emptyStringValue,
    accountHolderNumber: emptyStringValue,
    accountHolderTown: emptyStringValue,
    accountHolderZip: emptyStringValue,
    sepaAccepted: false,
    ibanAccount: emptyStringValue,
    bic: emptyStringValue,
    bank: emptyStringValue,
    paymentType: emptyStringValue,
    isPrivacyAccepted: false,
    isConditionsAccepted: false, // new
    isSchufaAccepted: false, // new
    isCouponAccepted: false, // new
    isTerminateSupplyContractAccepted: false, // new
    isOrderIssued: false, // new
    isNewEntry: true, // TODO: setting this value doesn't change initial data, still false
  },
  energyErrors: {}
};
