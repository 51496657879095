import {
  ADDRESS_STEP,
  RECEPTION_STEP,
  PAYMENT_STEP,
  PRIVACY_STEP
} from './constants';

const emptyStringValue = '';

export const skyInitialState = {
  modalType: emptyStringValue,
  modalProps: null,
  ident: 'webid',
  skyLogic: {
    activeStepName: ADDRESS_STEP,
    isEditMode: false,
    steps: [
      {
        type: ADDRESS_STEP,
        title: 'Rechnungsadresse / Lieferadresse',
        isValid: false
      },
      {
        type: RECEPTION_STEP,
        title: 'Empfang',
        isValid: false
      },
      {
        type: PAYMENT_STEP,
        title: 'Bezahlung',
        isValid: false
      },
      {
        type: PRIVACY_STEP,
        title: 'Datenschutz & AGB',
        isValid: false
      }
    ]
  },
  skyData: {
    isHdOptionInclusive: false,
    hdOption: false,

    salutation: emptyStringValue,
    firstName: emptyStringValue,
    lastName: emptyStringValue,
    birthDate: emptyStringValue,
    email: emptyStringValue,
    email2: emptyStringValue,
    mobile: emptyStringValue,
    street: emptyStringValue,
    streetNumber: emptyStringValue,
    adressExtra: emptyStringValue,
    zip: emptyStringValue,
    town: emptyStringValue,
    isDifferentDeliveryAddress: false,
    deliverySalutation: emptyStringValue,
    deliveryFirstName: emptyStringValue,
    deliveryLastName: emptyStringValue,
    deliveryStreet: emptyStringValue,
    deliveryStreetNumber: emptyStringValue,
    deliveryAddressExtra: emptyStringValue,
    deliveryZip: emptyStringValue,
    deliveryTown: emptyStringValue,

    tvReceptionType: emptyStringValue,

    isDifferentAccountHolder: false,
    accountHolderSalutation: emptyStringValue,
    accountHolderFirstName: emptyStringValue,
    accountHolderLastName: emptyStringValue,
    ibanAccount: emptyStringValue,
    bicBlz: emptyStringValue,
    bank: emptyStringValue,

    isPrivacyAccepted: false,
    isConsentDataUsage: false
  },
  skyErrors: {}
};
