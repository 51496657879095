import AXIOS from "axios";
import { SubmissionError } from "redux-form";

import {
    CHANGE_INPUT,
    CHECKOUT_TYPE,
    CLOSE_STATIC,
    CONFIG_STEP_VISIBILITY,
    CONTRACT_PARTNER,
    EDIT_STEP,
    FINALIZE_COMPANY_AGREEMENT_FLOW,
    HANDLE_AJAX_ERROR,
    INIT_CHECKOUT_MODEL,
    INIT_FORM_STATE,
    NEXT_STEP,
    OPEN_CP_MODAL,
    OPEN_STATIC,
    PREVIOUS_STEP,
    PROCEED_CHECKOUT,
    PROCEED_CHECKOUT_AFTER_EDIT,
    SUPERSELECT_SECOND_SIM_ALLNETFLAT,
    SET_BANK_NAME,
    SET_CITY_NAME,
    SET_CITY_NAME_DELIVERY,
    SET_PAYMENT_TYPE,
    SET_STREET_DROP_DOWN,
    SET_STREET_DROP_DOWN_DELIVERY,
    SET_SUBCARDS_COUNT,
    SET_VOUCHER_CODE,
    SHOW_END_OF_CONTRACT,
    SHOW_PROVIDER_FORM_AREA,
    START_COMPANY_AGREEMENT_FLOW,
    VISIBILITY_RESIDENCE_PERMIT,
    VISIBILITY_RESIDENCE_PERMIT_VALID_UNTIL,
    VISIBILITY_STEP_5,
    STEP_VISIBILITY,
    STORE_RESPONSE,
    TOGGLE_ACCORDION,
    TOGGLE_HIGHLIGHT,
    TOGGLE_INFOBOX,
    TOGGLE_PROVIDER_FORM,
    UNSET_BANK_NAME,
    UPDATE_TARIFF_BASIC_CHARGE,
    TARIFF_TYPE,
    VALIDATE_STEP,
    VALIDATION_BACKEND_FAILED,
    VISIBILITY_BIC_BLZ_FIELD,
    EECC_GENERATE_DOCUMENT,
    EECC_DOWNLOAD_DOCUMENT,
    GET_PDF_FILES_FROM_PROVIDER,
    USER_HAS_GOT_THE_PDF_FILE,
    SEND_MAIL_TO_PROVIDER,
} from "../global/types";

import {
    getBankName as getBankNameData
} from "../global/helper/checkout";

export const camelToDash = (str) => str.replace(/\W+/g, '-')
    .replace(/([a-z\d])([A-Z])/g, '$1-$2')
    .toLowerCase();

export const init = () => (dispatch) => {
    dispatch({
        type: INIT_CHECKOUT_MODEL,
    });
};

export const changeInput = (step, label, value) => ({
    type: CHANGE_INPUT,
    data: { step, label, value },
});

export const proceedCheckout = (currentStep) => ({
    type: PROCEED_CHECKOUT,
    data: currentStep,
});

export const proceedCheckoutAfterEdit = (currentStep) => ({
    type: PROCEED_CHECKOUT_AFTER_EDIT,
    data: currentStep,
});

export const validateStep = (step) => ({
    type: VALIDATE_STEP,
    data: step,
});

export const nextStep = (stepId) => ({
    type: NEXT_STEP,
    data: stepId,
});

export const previousStep = (historyState) => ({
    type: PREVIOUS_STEP,
    historyState,
});

export const editStep = (stepId) => ({
    type: EDIT_STEP,
    data: stepId,
});

export const openModal = (value, modalID) => ({
    type: OPEN_CP_MODAL,
    data: value,
    modal: modalID,
});

export const storeResponse = (key, data) => ({
    type: STORE_RESPONSE,
    key,
    data,
});

export const setBankName = (data) => ({
    type: SET_BANK_NAME,
    data,
});

export const unsetBankName = (data) => ({
    type: UNSET_BANK_NAME,
    data,
});

export const handleAjaxError = (error) => ({
    type: HANDLE_AJAX_ERROR,
    error,
});

export const validationBackEndFailed = (data, stepId) => ({
    type: VALIDATION_BACKEND_FAILED,
    data,
    stepId,
});

export const getNationalitiesData = () => {
    const url = "/checkout/nationalities/";
    return AXIOS.get(url);
};

export const getNationalities = () =>
    function (dispatch) {
        try {
            return getNationalitiesData().then(
                (response) =>
                    dispatch(storeResponse("nationalities", response.data)),
                (error) => {
                    // manage error in response
                    dispatch(handleAjaxError(error));
                }
            );
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const getResidencePermitsData = () => {
    const url = "/checkout/residence-permits/";
    return AXIOS.get(url);
};

export const getResidencePermits = () =>
    function (dispatch) {
        try {
            return getResidencePermitsData().then(
                (response) =>
                    dispatch(storeResponse("residencePermits", response.data)),
                (error) => {
                    // manage error in response
                }
            );
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const getProvidersData = () => {
    const url = "/checkout/providers/";
    return AXIOS.get(url);
};

export const getProviders = () =>
    function (dispatch) {
        try {
            return getProvidersData().then(
                (response) =>
                    dispatch(storeResponse("providers", response.data)),
                (error) => {
                    // manage error in response
                }
            );
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const getCarriersData = () => {
    const url = "/checkout/carriers/";
    return AXIOS.get(url);
};

export const getCarriers = () =>
    function (dispatch) {
        try {
            return getCarriersData().then(
                (response) =>
                    dispatch(storeResponse("carriers", response.data)),
                (error) => {
                    // manage error in response
                }
            );
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const getBankName = (ibanKonto, bicBlz) =>
    function (dispatch) {
        try {
            return getBankNameData(ibanKonto, bicBlz).then((response) => {
                if (response.data.hasOwnProperty("code")) {
                    dispatch(unsetBankName(response.data));
                    return Promise.reject(response);
                }
                dispatch(setBankName(response.data));
            });
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const getDetailsModalData = (modal, id) => {
    let url = `/checkout/${camelToDash(modal)}/`;
    url = typeof id !== "undefined" ? `${url + id}/` : url;
    return AXIOS.get(url);
};

export const openContractPartnerModal = (value, modalID) => ({
    type: OPEN_CP_MODAL,
    data: value,
    modal: modalID,
});

export const getDetailsModal = (modal, id = undefined) =>
    function (dispatch) {
        try {
            return getDetailsModalData(modal, id).then((response) => {
                dispatch(storeResponse(modal, response.data));
                dispatch(openContractPartnerModal(true, modal));
            });
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const proceedValidation = (_formState, stepId, itWasAlreadyEdited) =>
    function (dispatch) {
        return proceedValidationData(_formState, stepId)
            .then((response) => {
                if (Object.keys(response.data).length === 0) {
                    if (itWasAlreadyEdited) {
                        dispatch(proceedCheckoutAfterEdit(stepId));
                    } else {
                        dispatch(proceedCheckout(stepId));
                        dispatch(nextStep(Number(stepId) + 1));
                    }
                } else if (response.data.webId) {
                    dispatch(editStep(1));
                    dispatch(openModal(true, "webidError"));
                } else {
                    throw new SubmissionError(response.data);
                }
            })
            .catch((e) => {
                if (
                    e.name === "SubmissionError" ||
                    e.message === "Submit Validation Failed"
                ) {
                    // just throw up the error. Redux-form will manage it later on.
                    throw e;
                } else if ((e.name = "Error")) {
                    // General Error
                    dispatch(openModal(true, "networkError"));
                } else {
                    // missing check kind of error!
                    dispatch(openModal(true, "sessionExpired"));
                }
            });
    };

export const proceedValidationData = (formPartial = {}, stepId) => {
    let url;
    url = `/checkout/validate/${stepId}/`;
    return AXIOS.post(url, formPartial);
};

export const setCityName = (data) => ({
    type: SET_CITY_NAME,
    data,
});

export const setStreetDropDown = (data) => ({
    type: SET_STREET_DROP_DOWN,
    data,
});

export const setCityNameDelivery = (data) => ({
    type: SET_CITY_NAME_DELIVERY,
    data,
});

export const setStreetDropDownDelivery = (data) => ({
    type: SET_STREET_DROP_DOWN_DELIVERY,
    data,
});

export const getAddressData = (zip) => {
    const url = "/helper/get-address/";
    return AXIOS.post(url, { zip });
};

export const getAddress = (zip) =>
    function (dispatch) {
        try {
            return getAddressData(zip).then(
                (response) => {
                    if (response.data.hasOwnProperty("cities")) {
                        dispatch(
                            setCityName(Object.keys(response.data.cities))
                        );
                    }
                    if (response.data.hasOwnProperty("streets")) {
                        dispatch(
                            setStreetDropDown(
                                Object.keys(response.data.streets)
                            )
                        );
                    }
                },
                (error) => {
                    // manage error in response
                }
            );
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const getDeliveryAddress = (zip) =>
    function (dispatch) {
        try {
            return getAddressData(zip).then(
                (response) => {
                    if (response.data.hasOwnProperty("cities")) {
                        dispatch(
                            setCityNameDelivery(
                                Object.keys(response.data.cities)
                            )
                        );
                    }
                    if (response.data.hasOwnProperty("streets")) {
                        dispatch(
                            setStreetDropDownDelivery(
                                Object.keys(response.data.streets)
                            )
                        );
                    }
                },
                (error) => {
                    // manage error in response
                }
            );
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const togglePreviousProviderForm = () => ({
    type: TOGGLE_PROVIDER_FORM,
});

export const showPreviousProviderActiveArea = (area) => ({
    type: SHOW_PROVIDER_FORM_AREA,
    area,
});

export const toggleInfoBox = (state, infoBox) => ({
    type: TOGGLE_INFOBOX,
    infoBox,
    state,
});

export const startCompanyAgreementFlow = () => ({
    type: START_COMPANY_AGREEMENT_FLOW,
});

export const finalizeCompanyAgreementFlow = () => ({
    type: FINALIZE_COMPANY_AGREEMENT_FLOW,
});

export const setResidencePermitVisibility = (data) => ({
    type: VISIBILITY_RESIDENCE_PERMIT,
    data,
});

export const setResidencePermitValidUntilVisibility = (data) => ({
    type: VISIBILITY_RESIDENCE_PERMIT_VALID_UNTIL,
    data,
});

export const toggleNumberPortability = (value) => ({
    type: VISIBILITY_STEP_5,
    value,
});

export const toggleStep = (value) => ({
    type: VISIBILITY_STEP_5,
    value,
});

export const setStepVisibility = (data) => ({
    type: STEP_VISIBILITY,
    value: data,
});

export const configStepVisibility = (data) => ({
    type: CONFIG_STEP_VISIBILITY,
    value: data,
});

export const initFormState = (formState) => ({
    type: INIT_FORM_STATE,
    formState,
});

export const toggleAccordion = (stepId) => ({
    type: TOGGLE_ACCORDION,
    stepId,
});

export const toggleHighlight = (obj) => ({
    type: TOGGLE_HIGHLIGHT,
    highlight: obj.highlight,
    product: obj.product,
});

export const openStatic = (value) => ({
    type: OPEN_STATIC,
    data: value,
});

export const closeStatic = () => ({
    type: CLOSE_STATIC,
    data: null,
});

export const showEndOfContract = (show) => ({
    type: SHOW_END_OF_CONTRACT,
    show,
});

export const getMnpNetPrefixesData = () => {
    const url = "/checkout/mnp-net-prefixes/";
    return AXIOS.get(url);
};

export const getMnpNetPrefixes = () =>
    function (dispatch) {
        try {
            return getMnpNetPrefixesData().then(
                (response) => {
                    const prefixesTemp = JSON.parse(response.data[0].value);
                    dispatch(
                        storeResponse(
                            "mnpNetPrefixes",
                            _generatePrefixOptions(prefixesTemp)
                        )
                    );
                },
                (error) => {
                    // manage error in response
                }
            );
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const showBicBlzField = (data) => ({
    type: VISIBILITY_BIC_BLZ_FIELD,
    data,
});

export const setVoucherCode = (data) => ({
    type: SET_VOUCHER_CODE,
    data,
});

const _generatePrefixOptions = (rawPrefixArr) => {
    const mpnNetPrefixes = [];
    rawPrefixArr.forEach((value) => {
        mpnNetPrefixes.push({
            name: value,
            value,
        });
    });
    return mpnNetPrefixes;
};

export const setCheckoutType = (data) => ({
    type: CHECKOUT_TYPE,
    data,
});

export const setContractPartner = (data) => ({
    type: CONTRACT_PARTNER,
    data,
});

export const setSuperselectSecondSimAllnetFlat = (data) => ({
    type: SUPERSELECT_SECOND_SIM_ALLNETFLAT,
    data,
});

export const updateTariffBasicCharge = (value, amount) => ({
    type: UPDATE_TARIFF_BASIC_CHARGE,
    data: {
        value,
        amount,
    },
});

export const setSubCardsCount = (data) => ({
    type: SET_SUBCARDS_COUNT,
    data,
});

export const setPaymentType = (data) => ({
    type: SET_PAYMENT_TYPE,
    data,
});

// EECC ACTIONS
export const sendMailToProvider = () => dispatch => {
    dispatch({
        type: SEND_MAIL_TO_PROVIDER,
        data: {
            eeccIsLoading: true,
            eeccAccepted: true,
            eeccSuccess: true
        }
    })
    const url = `/checkout/eecc/sendMail/`;
    AXIOS.get(url, {
        timeout: 60000,
    })
    .then(response =>
        dispatch({
            type: SEND_MAIL_TO_PROVIDER,
            data: {
                eeccAccepted: true,
                eeccIsLoading: false,
                eeccSuccess: response.data.success
            }
        })
    )
    .catch((error) => {
        console.warn(error)
        dispatch({
            type: SEND_MAIL_TO_PROVIDER,
            data: {
            eeccAccepted: true,
            eeccIsLoading: false,
            eeccSuccess: false
            }
        })
    })
};

export const getPdfFilesFromProvider = (provider) => dispatch => {
    dispatch({
        type: GET_PDF_FILES_FROM_PROVIDER,
        data: {
            eeccIsLoading: true,
            eeccAccepted: false,
            eeccSuccess: true
        }
    })
    const url = `/checkout/eecc/generateDocumentSync/`;
    AXIOS.get(url, {
        timeout: 30000,
    })
    .then(response => {
        if(provider === "freenet" || provider === "klarmobil") {
            dispatch({
                type: GET_PDF_FILES_FROM_PROVIDER,
                data: {
                    eeccAccepted: false,
                    eeccIsLoading: false,
                    eeccSuccess: response.data.success,
                    eeccPdfFile: response.data.document.documentReferences,
                    eeccPdfFileMk: response.data.document.documentReferencesMk
                }
            })
        }else (
            dispatch({
                type: GET_PDF_FILES_FROM_PROVIDER,
                data: {
                    eeccAccepted: false,
                    eeccIsLoading: false,
                    eeccSuccess: response.data.success,
                    eeccPdfFile: response.data.document.documentReferences[0].documentLink
                }
            })
        )
    })
    .catch((error) => {
        console.warn(error)
        dispatch({
            type: GET_PDF_FILES_FROM_PROVIDER,
            data: {
                eeccAccepted: true,
                eeccIsLoading: false,
                eeccSuccess: false,
                eeccPdfFile: undefined,
                eeccPdfFileMk: undefined
            }
        })
    })
};

export const userHasGotThePdfFile = () => dispatch => {
    dispatch({
        type: USER_HAS_GOT_THE_PDF_FILE,
        data: {
            eeccIsLoading: false,
            eeccAccepted: true,
            eeccSuccess: true
        }
    })
};

export const tariffType = (data) => dispatch => {
    dispatch({
        type: TARIFF_TYPE,
        data,
    })
};

export const eeccGenerateDocument = () => dispatch => {
    dispatch({
        type: EECC_GENERATE_DOCUMENT,
        data: {
            eeccIsLoading: true,
            eeccAccepted: false,
            eeccSuccess: true
        }
    })
    const url = `/checkout/eecc/generateDocument`;
    AXIOS.get(url, {
        timeout: 60000,
    })
    .then(response =>
        dispatch({
            type: EECC_GENERATE_DOCUMENT,
            data: {
                eeccAccepted: false,
                eeccIsLoading: false,
                eeccSuccess: response.data.success,
                eeccPdfFile: response.data.document.documentReferences,
            }
        })
    )
    .catch((error) => {
        console.warn(error)
        dispatch({
            type: EECC_GENERATE_DOCUMENT,
            data: {
                eeccAccepted: true,
                eeccIsLoading: false,
                eeccSuccess: false
            }
        })
    })
}

export const eeccDownloadDocument = () => dispatch => {
    dispatch({
        type: EECC_DOWNLOAD_DOCUMENT,
        data: {
            eeccIsLoading: true,
            eeccAccepted: false,
            eeccSuccess: true
        }
    })
    const url = `/checkout/eecc/downloadDocument`;
    AXIOS.get(url, {
        timeout: 60000,
    })
    .then(response =>
        dispatch({
            type: EECC_DOWNLOAD_DOCUMENT,
            data: {
                eeccAccepted: false,
                eeccIsLoading: false,
                eeccSuccess: response.data.success,
                eeccPdfFile: response.data.document.documentReferences,
            }
        })
    )
    .catch((error) => {
        console.warn(error)
        dispatch({
            type: EECC_DOWNLOAD_DOCUMENT,
            data: {
                eeccAccepted: true,
                eeccIsLoading: false,
                eeccSuccess: false
            }
        })
    })
}
