/** Checkout Types */
export const SKY_CHECKOUT = 'sky';
export const ENERGY_CHECKOUT = 'energy';
export const DSL_CHECKOUT = 'dsl';

/** Checkout reducer objects */

export const CHECKOUT_LOGIC = 'Logic';
export const CHECKOUT_DATA = 'Data';
export const CHECKOUT_ERRORS = 'Errors';
export const CHECKOUT_FORM_META = 'FormMeta';

/** Checkout modals */

export const SHOPING_CART_DELIVERY_MODAL = 'shoppingCartDeliveryModal';
export const WEB_ID_UNAVAILABLE_MODAL = 'webidUnavailableModal';
export const DELIVERY_MODAL = 'deliveryModal';
export const WEB_ID_HOW_TO_MODAL = 'webidHowtoModal';
export const DHL_IDENT_MODAL = 'dhlIdentModal';
export const SHOPPING_CART_GIFT_CARD_MODAL = 'shoppingCartGiftCardModal';
export const CP_INFO_MODAL = 'cpInfoModal';
export const PROVIDER_INFO_MODAL = 'providerInfoModal';
export const AGE_MODAL = 'ageModal';
export const CONTRACT_EXTENSION_MODAL = 'contractExtensionModal';
export const TIP_FOR_CONTRACT_NOT_TERMINATED_MODAL = 'tipForContractNotTerminatedModal';
export const TIP_FOR_PREPAID_CONTRACT_NOT_TERMINATED = 'tipForPrepaidContractNotTerminated';
export const PERSONAL_ID_MODAL = 'personalIdModal';
export const SESSION_EXPIRED_MODAL = 'sessionExpiredModal';
export const NETWORK_ERROR_MODAL = 'networkErrorModal';
export const WEB_ID_ERROR_MODAL = 'webidErrorModal';
export const GIFTCARD_INFO_MODAL = 'GiftcardInfoModal';
export const VOUCHER_CODE_INFO_MODAL = 'vouchercodeInfoModal';
export const SIM_CARD_FORMAT_MODAL = 'SimCardFormatModal';

/** Checkout errors */
export const EMPTY = 'EMPTY';
export const VALID_OLD_PROVIDER_PREFIX = 'VALID_OLD_PROVIDER_PREFIX';
export const RETURN_FALSE = 'RETURN_FALSE';
export const ACCEPTED = 'ACCEPTED';
export const ALPHANUMERIC = 'ALPHANUMERIC';
export const PACK_STATION = 'PACK_STATION';
export const EMPTY_IBAN = 'EMPTY_IBAN';
export const SHORT_FORMAT = 'SHORT_FORMAT';
export const WRONG_FORMAT = 'WRONG_FORMAT';
export const WRONG_FORMAT_MD = 'WRONG_FORMAT_MD';
export const WRONG_FORMAT_IBAN = 'WRONG_FORMAT_IBAN';
export const WRONG_FORMAT_BIC = 'WRONG_FORMAT_BIC';
export const NOT_MATCH = 'NOT_MATCH';
export const NO_NET_PREFIX = 'NO_NET_PREFIX';
export const NO_NET_PREFIX_O2 = 'NO_NET_PREFIX_O2';
export const TOO_YOUNG = 'TOO_YOUNG';
export const TOO_YOUNG18 = 'TOO_YOUNG18';
export const TOO_YOUNG16 = 'TOO_YOUNG16';
export const TOO_OLD = 'TOO_OLD';
export const TOO_SHORT = 'TOO_SHORT';
export const SAME_PROVIDER = 'SAME_PROVIDER';
export const DATE_IN_THE_PAST = 'DATE_IN_THE_PAST';
export const DATE_NOT_IN_PAST = 'DATE_NOT_IN_PAST';
export const DATE_NOT_IN_PAST_4 = 'DATE_NOT_IN_PAST_4';
export const DATE_NOT_IN_PAST_6 = 'DATE_NOT_IN_PAST_6';
export const DATE_NOT_FURTHER_THAN_8 = 'DATE_NOT_FURTHER_THAN_8';
export const DATE_NOT_FURTHER_THAN_12 = 'DATE_NOT_FURTHER_THAN_12';
export const DATE_NOT_FURTHER_THAN_24 = 'DATE_NOT_FURTHER_THAN_24';
export const STRING_CONTAINS_ONLY_DIGITS = 'STRING_CONTAINS_ONLY_DIGITS';
export const STRING_CONTAINS_NO_WHITESPACE = 'STRING_CONTAINS_NO_WHITESPACE';
export const MAX_TWENTY_CHARACTERS = 'MAX_TWENTY_CHARACTERS';
export const MAX_FIFTY_CHARACTERS = 'MAX_FIFTY_CHARACTERS';
export const MIN_TWO_DIGITS = 'MIN_TWO_DIGITS';
export const MIN_TWO_CHARACTERS = 'MIN_TWO_CHARACTERS';
export const DATE_TOO_FAR_IN_THE_FUTURE = 'DATE_TOO_FAR_IN_THE_FUTURE';
export const PORTABILITY_DATE_TOO_EARLY = 'PORTABILITY_DATE_TOO_EARLY';
export const PORTABILITY_DATE_TOO_LATE = 'PORTABILITY_DATE_TOO_LATE';
export const PORTABILITY_DATE_TOO_LATE_OTHER = 'PORTABILITY_DATE_TOO_LATE_OTHER';
export const WRONG_LENGTH = 'WRONG_LENGTH';

export const SHARED_ERROR = 'SHARED_ERROR';
export const SHARED_ERROR_ZIP_STREET_NOT_MATCH = 'SHARED_ERROR_ZIP_STREET_NOT_MATCH';
export const SHARED_ERROR_ADDRESS_NOT_MATCH = 'SHARED_ERROR_ADDRESS_NOT_MATCH';
export const SHARED_ERROR_EMAIL_NOT_MATCH = 'SHARED_ERROR_EMAIL_NOT_MATCH';
export const SHARED_ERROR_BANK_ACCOUNT_NOT_MATCH = 'SHARED_ERROR_BANK_ACCOUNT_NOT_MATCH';
export const SHARED_ERROR_IBAN_NOT_MATCH = 'SHARED_ERROR_IBAN_NOT_MATCH';
export const SHARED_ERROR_NEEDS_RESIDENCY_PERMIT = 'SHARED_ERROR_NEEDS_RESIDENCY_PERMIT';

export const TOO_FAR_IN_THE_FUTURE = 'TOO_FAR_IN_THE_FUTURE';
export const VALID_AREA_CODE = 'VALID_AREA_CODE';
export const NATIONAL_AREA_CODE = 'NATIONAL_AREA_CODE';
export const VALID_PHONE_NUMBER = 'VALID_PHONE_NUMBER';

export const VALID_NAME = 'VALID_NAME';
export const VALID_BIRTH_DATE_AGE = 'VALID_BIRTH_DATE_AGE';
export const VALID_BIRTH_DATE = 'VALID_BIRTH_DATE';
export const MIN_AGE_18 = 'MIN_AGE_18';
export const VALID_ZIP_CODE = 'VALID_ZIP_CODE';
export const VALID_TOWN = 'VALID_TOWN';
export const VALID_STREET = 'VALID_STREET';
export const VALID_STREET_NUMBER = 'VALID_STREET_NUMBER';
export const VALID_CALL_BACK_TIME = 'VALID_CALL_BACK_TIME';

export const VALID_IBAN = 'IS_VALID_IBAN';

export const VALID_PIN_O2 = 'VALID_PIN_O2';

/** Checkout CodeCeption */

export const CC_BTN_BACK = 'btnBack';
export const CC_BTN_CLEAR = 'btnClear';
export const CC_BTN_SUBMIT = 'btnSubmit';
export const CC_BTN_SUBMITDISABLED = 'btnSubmitDisabled';
export const CC_BTN_CLOSE = 'btnClose';
export const CC_BTN_OPEN = 'btnOpen';
export const CC_BTN_DETAILS = 'btnDetail';
export const CC_BTN_MORE = 'btnMore';
export const CC_BTN_ADDTOCART = 'btnAddToCart';
export const CC_BTN_EDIT = 'btnEdit';

// Before Adress Step
export const CC_HD_OPTION = 'hdOption';

// Address Step
export const CC_SALUTATION_FEMALE = 'salutation_female';
export const CC_SALUTATION_MALE = 'salutation_male';
export const CC_FIRSTNAME = 'firstname';
export const CC_LASTNAME = 'lastname';
export const CC_BIRTHDATE = 'birthDate';
export const CC_ZIP = 'zip';
export const CC_TOWN = 'city';
export const CC_STREET = 'street';
export const CC_STREET_NUMBER = 'streetnumber';
export const CC_ADDRESS_EXTRA = 'addressExtra';
export const CC_MOBILE = 'mobile';

// for energy checkout
export const CC_AREA_CODE = 'areaCode';
export const CC_PHONE_NUMBER = 'phoneNumber';

export const CC_EMAIL = 'email';
export const CC_EMAIL_REPEAT = 'emailRepeat';
export const CC_IS_DIFFERENT_DELIVERY_ADDRESS = 'isDifferentDeliveryAddress';

export const CC_TV_RECEPTION_TYPE_SATELLITE = 'tvReception_satellite';
export const CC_TV_RECEPTION_TYPE_CABLE = 'tvReception_cable';

export const CC_NEW_ENTRY = 'newEntry';
export const CC_CHANGE_OF_PROVIDER = 'changeOfProvider';

export const CC_DESIRED_DELIVERY_DATE = 'desiredDeliveryDate';
export const CC_METER_NUMBER = 'meterNumber';
export const CC_MARKET_LOCATION = 'marketLocation';
export const CC_OLD_CUSTOMER_NUMBER = 'oldCustomerNumber';
export const CC_OLD_PROVIDER = 'oldProvider';
export const CC_OLD_PROVIDER_CANCELLED = 'oldProviderCancelled';
export const CC_OLD_PROVIDER_CANCEL_DATE = 'oldProviderCancelDate';

export const CC_CABLE = 'cable';

export const CC_SEPA_ACCEPTED = 'sepaAccepted';

export const CC_IS_DIFFERENT_ACCOUNTHOLDER = 'isDifferentAccountHolder';
export const CC_IBAN_ACCOUNT = 'iban_accountnumber';
export const CC_BIC_BLZ = 'bicBlz';
export const CC_BANK = 'bank-name';

export const CC_IS_PRIVACY_ACCEPTED = 'isPrivacyAccepted';
export const CC_IS_CONSENT_DATA_USAGE = 'isConsentDataUsage';
export const CC_IS_CANCELLATION_TERMS_ACCEPTED = 'isCancellationTermsAccepted';
export const CC_IS_CONDITIONS_ACCEPTED = 'isConditionsAccepted';
export const CC_IS_SCHUFA_ACCEPTED = 'isSchufaAccepted';
export const CC_IS_COUPON_ACCEPTED = 'isCouponAccepted';
export const CC_IS_TERMINATE_SUPPLY_CONTRACT_ACCEPTED = 'isTerminateSupplyContractAccepted';
export const CC_IS_ORDER_ISSUED = 'isOrderIssued';

// for dsl CHECKOUT

export const CC_PHONE_NUMBER_PORTABILITY_FALSE = 'phoneNumberPortabilityFalse';
export const CC_PHONE_NUMBER_PORTABILITY_TRUE = 'phoneNumberPortabilityTrue';
export const CC_EXISTING_CONTRACT_FALSE = 'existingContractFalse';
export const CC_EXISTING_CONTRACT_TRUE = 'existingContractTrue';
export const CC_FAST_ACTIVATION_TRUE = 'fastActivationTrue';
export const CC_FAST_ACTIVATION_FALSE = 'fastActivationFalse';
export const CC_CARRIER_IDENTIFICATION = 'carrierIdentification';
export const CC_CARRIER_CUSTOMER_ID = 'carrierCustomerId';

export const CC_NUMBER_PORTING_HAND_DATA_IN_LATER = 'handDataInLater';
export const CC_NUMBER_PORTING_SINGLE_PHONE_NUMBER = 'singlePhoneNumber';
export const CC_NUMBER_PORTING_PHONE_NUMBERS = 'phoneNumbers';
export const CC_NUMBER_PORTING_PORT_ALL_NUMBERS = 'portAllNumbers';
export const CC_EXTENSIONS_0 = 'extensions_0';

export const CC_SUBSCRIBER_SUBSCRIBER = 'subscriber';
export const CC_SUBSCRIBER_ADDITONAL_SUBSCRIBER = 'additionalSubscriber';
export const CC_SUBSCRIBER_ALTERNATIVE_SUBSCRIBER = 'alternativeSubscriber';

export const CC_SUBSCRIBER_SALUTATION_FEMALE = 'subscriberSalutationMen';
export const CC_SUBSCRIBER_SALUTATION_MALE = 'subscriberSalutationMen';

export const CC_SUBSCRIBER_FIRSTNAME = 'subscriberFirstname';
export const CC_SUBSCRIBER_LASTNAME = 'subscriberLastname';

export const SALUTATION_FEMALE = 'female';
export const SALUTATION_MALE = 'male';
