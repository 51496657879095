export const FETCH_BANK_NAME = '@@checkout/FETCH_BANK_NAME';
export const FETCH_ADDRESS_DATA = '@@checkout/FETCH_ADDRESS_DATA';

/**
 * @param {string} params
 * @param {function} callback
 *
 * @returns {{type: string, callback: function}}
 */
export function fetchBankName(params, callback) {
  return {
    type: FETCH_BANK_NAME,
    params,
    callback
  };
}

export function fetchAddressData(params, callback) {
  return {
    type: FETCH_ADDRESS_DATA,
    params,
    callback
  };
}
