export default (id, startAtCurrentPosition) => {
  if (id.toString().substr(0, 1) === '#') {
    id = id.substr(1);
  }
  const target = document.getElementById(id);

  animate(document.scrollingElement || document.documentElement, 'scrollTop',
    '', startAtCurrentPosition
      ? (document.scrollingElement || document.documentElement).scrollTop
      : 0, target.offsetTop, 250, true);

  function animate(elem, style, unit, from, to, time, prop) {
    if (!elem) {
      return;
    }
    const start = new Date().getTime();

    var timer = setInterval(() => {
      const step = Math.min(1, (new Date().getTime() - start) / time);
      if (prop) {
        elem[style] = (from + step * (to - from)) + unit;
      } else {
        elem.style[style] = (from + step * (to - from)) + unit;
      }
      if (step === 1) {
        clearInterval(timer);
      }
    }, 25);
    if (prop) {
      elem[style] = from + unit;
    } else {
      elem.style[style] = from + unit;
    }
  }
};
