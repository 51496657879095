export const initialState = {
  form: {
    reason: '',
    reasonDetails: '',
    name: '',
    email: '',
    zipcode: '',
    orderid: '',
    message: '',
    privacy: 0
  },
  openStatic: null
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'OPEN_STATIC':
      return {
        ...state,
        openStatic: action.data
      };
    case 'CLOSE_STATIC':
      return {
        ...state,
        openStatic: null
      };

    case 'CHANGE_INPUT':
      return {
        ...state,
        form: {
          ...state.form,
          [action.data.label]: action.data.value
        }
      };

    default:
      return state;
  }
};

export default reducer;
