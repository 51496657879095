import { createSelector } from 'reselect';
import { every, findIndex } from 'lodash';
import { get } from 'lodash';
import { CHECKOUT_LOGIC, CHECKOUT_DATA, CHECKOUT_FORM_META } from './constants';

export const getNextStepNumber = (steps, activeStepIndex) => {
  if (steps.length === activeStepIndex + 1) {
    return activeStepIndex + 1;
  }

  return activeStepIndex + 2;
};

export const getNextStepName = (steps, activeStepIndex) => {
  if (steps.length === activeStepIndex + 1) {
    return steps[activeStepIndex].type;
  }

  return steps[activeStepIndex + 1].type;
};

export const selectActiveStep = createSelector(
  (state, props) => state.checkout[props.checkoutType + CHECKOUT_LOGIC].steps,
  (state, props) => state.checkout[props.checkoutType + CHECKOUT_LOGIC].activeStepName,
  (steps, activeStepName) => steps.find(step => step.type === activeStepName)
);

export const selectAreAllStepValid = createSelector(
  (state, props) => state.checkout[props.checkoutType + CHECKOUT_LOGIC].steps,
  steps => every(steps, 'isValid')
);

export const selectCheckoutProps = createSelector(
  state => state.checkout.modalType,
  state => state.checkout.modalProps,
  (state, props) => state.checkout[props.checkoutType + CHECKOUT_DATA],
  (state, props) => state.checkout[props.checkoutType + CHECKOUT_LOGIC].activeStepName,
  (state, props) => state.checkout[props.checkoutType + CHECKOUT_LOGIC].isEditMode,
  (state, props) => state.checkout[props.checkoutType + CHECKOUT_LOGIC].steps,
  selectActiveStep,
  (state, props) => state.checkout[props.checkoutType + CHECKOUT_FORM_META],
  (modalType, modalProps, checkoutData, activeStepName, isEditMode, checkoutSteps, activeStep, formMeta) => ({
    modalType,
    modalProps,
    checkoutData,
    activeStepName,
    isEditMode,
    checkoutSteps,
    activeStep,
    formMeta
  })
);

export const selectActiveStepNumber = createSelector(
  (state, props) => state.checkout[props.checkoutType + CHECKOUT_LOGIC].steps,
  (state, props) => state.checkout[props.checkoutType + CHECKOUT_LOGIC].activeStepName,
  (steps, activeStepName) => 1
    + findIndex(steps, step => step.type === activeStepName)
);

export const selectCheckoutEditButtonProps = createSelector(
  (state, props) => state.checkout[props.checkoutType + CHECKOUT_LOGIC].activeStepName,
  selectActiveStepNumber,
  (activeStepName, stepNumber) => ({
    activeStepName,
    stepNumber
  })
);

export const getLastInvalidStep = (state, checkoutType) => get(state.checkout,
  [checkoutType, 'steps']).find(step => !step.isValid);
