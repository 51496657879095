import _ from 'lodash';
import pageReducer from '@core/frontend/src/reducers/page';
import {
  SAVE_VALUE,
  SAVE_NORMALIZED_VALUE,
  SET_VALIDATION_ERROR,
  OPEN_CHECKOUT_MODAL,
  CLOSE_CHECKOUT_MODAL,
  CLEAR_CHECKOUT_ERRORS,
  SET_CHECKOUT_ERRORS,
  SET_ACTIVE_CHECKOUT_STEP,
  SET_EDIT_MODE_CHECKOUT,
  SET_VALID_CHECKOUT_STEP,
  SET_CHECKOUT_FORM_META,
  SEND_MAIL_TO_PROVIDER,
  USER_HAS_GOT_THE_PDF_FILE,
  GET_PDF_FILES_FROM_PROVIDER,
  SET_VOUCHER_CODE,
  SET_ADDRESS_VALIDATED,
} from './actions';
import {
  SET_CHECKOUT_ADDRESS_DATA,
  SET_DSL_CHECKOUT_DATA,
  SET_CHECKOUT_DELIVERY_ADDRESS_DATA
} from './DSLCheckout/DSLCheckoutAddressStep/actions';

import { INC_PORTING_NUMBER_COUNT, SET_CUSTOMER_EXISTING } from './DSLCheckout/DSLCheckoutConnectionStep/actions';

import { skyInitialState } from './SkyCheckout/initialState';
import { energyInitialState } from './EnergyCheckout/initialState';
import { dslInitialState } from './DSLCheckout/initialState';

export const initialState = {
  ...skyInitialState,
  ...energyInitialState,
  ...dslInitialState
};

export function checkoutReducer(state, action) {
  switch (action.type) {
    case INC_PORTING_NUMBER_COUNT:
      return {
        ...state,
        [action.checkoutDataType]: {
          ...state[action.checkoutDataType],
          numberPortingCount: state[action.checkoutDataType].numberPortingCount
            + 1
        }
      };

    case OPEN_CHECKOUT_MODAL:
      return {
        ...state,
        modalType: action.modalType,
        modalProps: action.modalProps
      };

    case CLOSE_CHECKOUT_MODAL:
      return {
        ...state,
        modalType: '',
        modalProps: null
      };

    case SAVE_VALUE:
      const newState = _.cloneDeep(state);
      const path = [action.checkoutDataType, action.key].join('.');
      _.set(newState, path, action.value);
      return newState;

    case SAVE_NORMALIZED_VALUE:
      const previousState = state[action.checkoutDataType][action.key];
      return {
        ...state,
        [action.checkoutDataType]: {
          ...state[action.checkoutDataType],
          [action.key]: action.func(action.value, previousState)
        }
      };

    case CLEAR_CHECKOUT_ERRORS:
      return {
        ...state,
        [action.checkoutErrorType]: action.fieldName ? { ..._.omit(state[action.checkoutErrorType], action.fieldName) } : {}
      };

    case SET_CHECKOUT_ERRORS:
      return {
        ...state,
        [action.checkoutErrorType]: action.errors
      };

    case SET_VALIDATION_ERROR:
      return {
        ...state,
        [action.checkoutErrorType]: {
          ...state[action.checkoutErrorType],
          [action.key]: action.message
        }
      };

    case SET_ACTIVE_CHECKOUT_STEP:
      return {
        ...state,
        [action.checkoutLogicType]: {
          ...state[action.checkoutLogicType],
          activeStepName: action.checkoutStep
        }
      };

    case SET_EDIT_MODE_CHECKOUT:
      return {
        ...state,
        [action.checkoutLogicType]: {
          ...state[action.checkoutLogicType],
          isEditMode: action.isEditMode
        }
      };

    case SET_VALID_CHECKOUT_STEP:
      return {
        ...state,
        [action.checkoutLogicType]: {
          ...state[action.checkoutLogicType],
          steps: state[action.checkoutLogicType].steps.map((step) => {
            if (step.type === action.checkoutStep) {
              return { ...step, isValid: action.value };
            }

            return step;
          })
        }
      };

    case SET_CHECKOUT_ADDRESS_DATA:
      return {
        ...state,
        dslCheckoutAddressData: action.payload
      };

    case SET_VOUCHER_CODE:
      return {
        ...state,
        voucherCode: action.payload
      };

    case SET_ADDRESS_VALIDATED:
      return {
        ...state,
        energyData: {
          ...state.energyData,
          addressValidated: action.payload
        }
      };

    case SET_CHECKOUT_DELIVERY_ADDRESS_DATA:
      return {
        ...state,
        dslCheckoutDeliveryAddressData: action.payload
      };

    case SET_CUSTOMER_EXISTING:
      return {
        ...state,
        dslData: {
          ...state.dslData,
          existingCustomer: action.payload
        }
    };

    case SET_CHECKOUT_FORM_META:
      return {
        ...state,
        [action.checkoutFormMeta]: {
          ...state[action.checkoutFormMeta],
          [action.fieldName]: {
            ..._.get(state, [action.checkoutFormMeta, action.fieldName], {}),
            ...action.payload
          }
        }
      };

    case SET_DSL_CHECKOUT_DATA:
      const newData = _.get(action, 'payload', []);
      return {
        ...state,
        dslData: {
          ...state.dslData,
          ...newData
        }
      };
    
    case SEND_MAIL_TO_PROVIDER:
      return {
        ...state,
        eeccAccepted: action.data.eeccAccepted,
        eeccIsLoading: action.data.eeccIsLoading,
        eeccSuccess: action.data.eeccSuccess,
      };

    case USER_HAS_GOT_THE_PDF_FILE:
      return {
        ...state,
        eeccAccepted: action.data.eeccAccepted,
        eeccIsLoading: action.data.eeccIsLoading,
        eeccSuccess: action.data.eeccSuccess,
      };
    
    case GET_PDF_FILES_FROM_PROVIDER:
      return {
          ...state,
          eeccAccepted: action.data.eeccAccepted,
          eeccIsLoading: action.data.eeccIsLoading,
          eeccSuccess: action.data.eeccSuccess,
          eeccPdfFile: action.data.eeccPdfFile
      };

    default:
      return state;
  }
}

export default function reducer(state = initialState, action = {}) {
  const pageModel = pageReducer(state, action);
  const headerModel = checkoutReducer(pageModel, action);
  return {
    ...state,
    ...pageModel,
    ...headerModel
  };
}
