export const initialState = {
  mobileMenuOpen: false,
  mobileMenuActiveCategory: null,
  mobileMenuActiveSubcategory: null,
  openedFlyout: null,
  contactModalOpen: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_MOBILE_MENU':
      if (state.mobileMenuOpen) {
        window.document.body.className = document.body.className.replace(
          ' mobile-nav-open', ''
        ).replace('mobile-nav-open', '');
      } else {
        window.document.body.className += ' mobile-nav-open';
      }

      return {
        ...state,
        mobileMenuOpen: !state.mobileMenuOpen
      };

    case 'CHANGE_MOBILE_MENU_CATEGORY':
      let category = action.data.category;

      if (category === state.mobileMenuActiveCategory) {
        category = null;
      }

      return {
        ...state,
        mobileMenuActiveCategory: category
      };

    case 'CHANGE_MOBILE_MENU_SUBCATEGORY':
      let subcategory = action.data.subcategory;

      if (subcategory === state.mobileMenuActiveSubcategory) {
        subcategory = null;
      }

      return {
        ...state,
        mobileMenuActiveSubcategory: subcategory
      };

    case 'CHANGE_OPEN_FLYOUT':
      return {
        ...state,
        openedFlyout: action.data.name
      };

    case 'OPEN_CONTACTMODAL':
      return {
        ...state,
        contactModalOpen: true
      };

    case 'CLOSE_CONTACTMODAL':
      return {
        ...state,
        contactModalOpen: false
      };

    default:
      return state;
  }
};

export default reducer;
