import { EVN_DECLINE } from './constants';

const emptyStringValue = '';

export const dslInitialState = {
  voucherCode: null,
  modalType: emptyStringValue,
  modalProps: null,
  ident: 'webid',
  eeccIsLoading: false,
  eeccAccepted: false,
  eeccSuccess: true,
  eeccPdfFile: "https://tarife.mediamarkt.de",
  dslLogic: {},
  dslData: {
    address: {
      street: 'Lange Reihe',
      streetNumber: 32,
      zipCode: 20099,
      town: 'Hamburg'
    },
    numberPortingCount: 1,
    phoneNumberPortability: false,
    existingCustomer: false,
    existingContract: false,
    fastActivation: true,
    carrierCustomerId: emptyStringValue,
    carrierIdentification: emptyStringValue,
    phoneNumberPortCount: '1',
    phoneNumberPortId: emptyStringValue,

    // hasExistingConnection is "Ist bereits ein eigener Anschluss vorhanden?" and shows "requestedNumberPorting" 
    hasExistingConnection: true,

    buildingType: emptyStringValue,
    floorType: emptyStringValue,
    alignmentType: emptyStringValue,
    floorNumber: emptyStringValue,
    currentProvider: emptyStringValue,
    activationTime: emptyStringValue,
    callBackTime: emptyStringValue,
    tariff: {
      setupPrice: emptyStringValue,
      monthlyPrice: emptyStringValue,
      legal_notes: [],
      provider: emptyStringValue,
      frontendName: emptyStringValue,
      bullets: [],
      promotionPeriod: emptyStringValue,
      duration: emptyStringValue,
      monthlypriceRenewal: emptyStringValue,
    },
    selectedOptions: {},
    prefixes: {
      prefix1: emptyStringValue,
      prefix2: emptyStringValue,
      prefix3: emptyStringValue,
      prefix4: emptyStringValue,
      prefix5: emptyStringValue,
      prefix6: emptyStringValue,
      prefix7: emptyStringValue,
      prefix8: emptyStringValue,
      prefix9: emptyStringValue,
      prefix10: emptyStringValue
    },
    extensions: {
      extension1: emptyStringValue,
      extension2: emptyStringValue,
      extension3: emptyStringValue,
      extension4: emptyStringValue,
      extension5: emptyStringValue,
      extension6: emptyStringValue,
      extension7: emptyStringValue,
      extension8: emptyStringValue,
      extension9: emptyStringValue,
      extension10: emptyStringValue
    },
    region: emptyStringValue,
    subscriber: {
      alternativeSubscriber: false,
      additionalSubscriber: false
    },
    endOfTermMode: {
      endTerm: 'NoTerm',
      endDate: false
    },
    hardwareSelection: emptyStringValue,
    availability: emptyStringValue,
    salutation: emptyStringValue,
    firstName: emptyStringValue,
    lastName: emptyStringValue,
    birthDate: emptyStringValue,
    email: emptyStringValue,
    email2: emptyStringValue,
    areaCode: emptyStringValue,
    phoneNumber: emptyStringValue,
    street: emptyStringValue,
    streetNumber: emptyStringValue,
    zip: emptyStringValue,
    town: emptyStringValue,
    isDifferentDeliveryAddress: false,
    deliverySalutation: emptyStringValue,
    deliveryFirstName: emptyStringValue,
    deliveryLastName: emptyStringValue,
    deliveryStreet: emptyStringValue,
    deliveryStreetNumber: emptyStringValue,
    deliveryZip: emptyStringValue,
    deliveryTown: emptyStringValue,
    deliveryType: emptyStringValue,
    desiredDeliveryDate: emptyStringValue,
    deliveryDate: emptyStringValue,
    deliveryTime: emptyStringValue,
    meterNumber: emptyStringValue,
    marketLocation: emptyStringValue,
    oldCustomerNumber: emptyStringValue,
    oldProvider: emptyStringValue,
    oldProviderCancelled: false,
    oldProviderCancelDate: emptyStringValue,
    isDifferentAccountHolder: false,
    accountHolderSalutation: emptyStringValue,
    accountHolderFirstName: emptyStringValue,
    accountHolderLastName: emptyStringValue,
    accountHolderStreet: emptyStringValue,
    accountHolderNumber: emptyStringValue,
    accountHolderTown: emptyStringValue,
    accountHolderZip: emptyStringValue,
    ibanAccount: emptyStringValue,
    bic: emptyStringValue,
    bank: emptyStringValue,
    paymentType: emptyStringValue,
    isPrivacyAccepted: false,
    isConditionsAccepted: false, // new
    isSchufaAccepted: false, // new
    isCouponAccepted: false, // new
    isTerminateSupplyContractAccepted: false, // new
    isOrderIssued: false, // new
    isNewEntry: true, // TODO: setting this value doesn't change initial data, still false

    // Telefonica specific initial values
    originalCarrier: emptyStringValue,
    requestedActivationWindow: 'NOT_SPECIFIED',
    requestedSpecificActivationTime: false,
    requestedActivationDate: emptyStringValue,

    // requestedNumberPorting is "Möchten Sie Ihre Rufnummer mitnehmen?"
    requestedNumberPorting: true,

    numberPortingInfos: {
      numberPortingInfo1: {
        ndc: emptyStringValue,
        subscriberNumber: emptyStringValue
      }
    },
    singleConnectionOwner: true,
    otherConnectionOwnerSalutation: emptyStringValue,
    otherConnectionOwnerFirstName: emptyStringValue,
    otherConnectionOwnerLastName: emptyStringValue,
    otherConnectionOwner2Salutation: emptyStringValue,
    otherConnectionOwner2FirstName: emptyStringValue,
    otherConnectionOwner2LastName: emptyStringValue,
    otherConnectionOwnerAgreement: false,
    personalIdentificationNumber: emptyStringValue,
    evn: EVN_DECLINE,
    preferred_communication: 'mail',

    // Headline: Bitte geben Sie hier Ihre bisherige Rufnummer des Anschlusses ein.
    oldProviderNumberPrefix: emptyStringValue,
    oldProviderNumberExtension: emptyStringValue,
    
    // is important for the DSL-Checkout
    eeccAccepted: false,
  },
  dslErrors: {
    extensions: {
      extension1: false,
      extension2: false,
      extension3: false,
      extension4: false,
      extension5: false,
      extension6: false,
      extension7: false,
      extension8: false,
      extension9: false,
      extension10: false
    },
    prefixes: {
      prefix1: false,
      prefix2: false,
      prefix3: false,
      prefix4: false,
      prefix5: false,
      prefix6: false,
      prefix7: false,
      prefix8: false,
      prefix9: false,
      prefix10: false
    },
    subscriberState: {
      subscriber: false,
      alternativeSubscriber: false,
      additionalSubscriber: false
    },
    subscriber: {
      subscriber: false,
      alternativeSubscriber: false,
      additionalSubscriber: false
    },
    endOfTermMode: {
      endTerm: false,
      endDate: false
    },
    availability: false,
    phoneNumberPortId: false
  }
};
