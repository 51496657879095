export const SET_ENERGY_CHECK = 'SET_ENERGY_CHECK';
export const SUBMIT_ENERGY_CHECK = 'SUBMIT_ENERGY_CHECK';
export const SET_ENERGY_CHECK_ERRORS = 'SET_ENERGY_CHECK_ERRORS';
export const SET_ENERGY_LOADER = 'SET_ENERGY_LOADER';
export const SET_ENERGY_RESULT = 'SET_ENERGY_RESULT';
export const SUBMIT_ZIP_CHECK = 'SUBMIT_ZIP_CHECK';
export const SET_PLACES_RESULT = 'SET_PLACES_RESULT';
export const SET_ENERGY_VOUCHER = 'SET_ENERGY_VOUCHER';

export function setEnergyCheck(payload) {
  return {
    type: SET_ENERGY_CHECK,
    payload
  };
}

export function submitEnergyCheck(payload) {
  return {
    type: SUBMIT_ENERGY_CHECK,
    payload
  };
}

export function setEnergyCheckErrors(payload) {
  return {
    type: SET_ENERGY_CHECK_ERRORS,
    payload
  };
}

export function setEnergyLoader(payload) {
  return {
    type: SET_ENERGY_LOADER,
    payload
  };
}

export function setEnergyResult(payload) {
  return {
    type: SET_ENERGY_RESULT,
    payload
  };
}

export function submitZipCheck(payload) {
  return {
    type: SUBMIT_ZIP_CHECK,
    payload
  };
}

export function setEnergyPlaces(payload) {
  return {
    type: SET_PLACES_RESULT,
    payload
  };
}

export function setEnergyVoucher(payload) {
  return {
    type: SET_ENERGY_VOUCHER,
    payload
  };
}