const UPDATE_STOCKS = "UPDATE_STOCKS";
const ADD_PRODUCT = "ADD_PRODUCT";
const SET_HARDWARE_VARIANT = "SET_HARDWARE_VARIANT";
const SET_HOVER_HARDWARE_VARIANT = "SET_HOVER_HARDWARE_VARIANT";

export function init() {
    return (dispatch) => {
        dispatch({
            type: "INIT_OVERVIEW_MODEL",
        });
    };
}

export function toggleFilterMenu() {
    return {
        type: "TOGGLE_FILTER_MENU",
    };
}

export const addProduct = (productId, product) => ({
    type: ADD_PRODUCT,
    data: { productId, product },
});

export function changeMobileFilterCategory(category) {
    return {
        type: "CHANGE_MOBILE_FILTER_CATEGORY",
        data: { category },
    };
}

export function addFilter(group, value) {
    return {
        type: "ADD_FILTER",
        data: { group, value },
    };
}

export function removeFilter(group, value) {
    return {
        type: "REMOVE_FILTER",
        data: { group, value },
    };
}

export function deleteFilters() {
    return {
        type: "DELETE_FILTERS",
        data: { filters: [] },
    };
}

export function updateFilter(group, value) {
    return {
        type: "UPDATE_FILTER",
        data: { group, value },
    };
}

export function setHistoryState(stateObj) {
    return {
        type: "SET_HISTORY_STATE",
        data: stateObj,
    };
}

export function addnFilterCandidate(group, value) {
    return {
        type: "ADD_FILTER_CANDIDATE",
        data: { group, value },
    };
}

export function addnFilterRemoveCandidate(group, value) {
    return {
        type: "ADD_FILTERREMOVE_CANDIDATE",
        data: { group, value },
    };
}

export function removenFilterCandidate(group, value) {
    return {
        type: "REMOVE_FILTERCANDIDATE",
        data: { group, value },
    };
}

export function removenFilterRemoveCandidate(group, value) {
    return {
        type: "REMOVE_FILTERCANDIDATE2REMOVE",
        data: { group, value },
    };
}

export const updateStocks = (value) => ({
    type: UPDATE_STOCKS,
    data: value,
});
export const setHardwareVariant = (productId, variant) => ({
    type: SET_HARDWARE_VARIANT,
    data: { productId, variant },
});
export const setHardwareHoverVariant = (productId, variant) => ({
    type: SET_HOVER_HARDWARE_VARIANT,
    data: { productId, variant },
});

export function removeSmartphoneInfobox() {
    return {
        type: "REMOVE_SMARTPHONE_INFOBOX",
    };
}

export function removeTabletInfobox() {
    return {
        type: "REMOVE_TABLET_INFOBOX",
    };
}

export function setRichSeoDataForOverview(data) {
    return {
        type: "SET_RICH_SEO_DATA_FOR_OVERVIEW",
        data,
    };
}

export function getRichSeoDataForOverview(data) {
    return {
        type: "GET_RICH_SEO_DATA_FOR_OVERVIEW",
        data,
    };
}
