import axios from "axios/index";
import { get, merge } from "lodash";

/**
 * Get stocks for offerGroups ( children - i.e. offers / hardwares / variants etc. )
 * Warning: Structure of given array MUST follow this schema:
 * [
 *  {
 *      id : id,
 *      offers: [
 *          {
 *              id : id,
 *              hardwares: [
 *                  {
 *                      id : id,
 *                      variants : [
 *                          { id : id }
 *                      ]
 *                  }
 *              ]
 *              accessories: [
 *                  {
 *                      id : id
 *                  }
 *              ]
 *          }
 *      ]
 *  }
 * ]
 *
 * @param offerGroups  // must not be an real offer group!! It's a genral grouping of offers
 * @param callback
 */
export default (offerGroups, callback) => {
    /* const stocksModel = {

         id: null,
         stocks: 9999,
         offers: null

     };


     const hardwareModification = () => {

         return {...stocksModel, variants }

     };

     const offersModification = (offers) => {


         const allowed = ['hardwares', 'accessory'];

         const filtered = offers.map(offer => {
             Object.keys(offer)
                 .filter(key => allowed.includes(key))
                 .reduce((obj, key) => {
                     obj[key] = hardwareModification(offer[key]);
                     return obj;
                 }, {});
         });

         //offers.map()

         //  offer['hardwares'] && offer['hardwares'].length ? hardwareModification() : {};


     };

     //  const exportGroups = offerGroups.map(offerGroup => offerGroup['offers'].map(offer => {
     const exportGroups = offerGroups.map(offerGroup => {

         return {
             ...stocksModel, id: offerGroup.sectionId, offers: offersModification(offerGroup.offers)


         };

     });


     ///offerGroup['offers'].map(offer => {


     //   }); */

    const stockedOfferGroups = offerGroups.map((offerGroup) => {
        const offers = offerGroup.offers.map((offer) => {
            const hardwares =
                offer.hardwares && offer.hardwares.length
                    ? offer.hardwares.map((hardware) => {
                          if (hardware) {
                              const variants = hardware.variants
                                  ? hardware.variants.map((variant) => ({
                                        id: variant.ebootisId,
                                        stock: 9999,
                                    }))
                                  : [];

                              return {
                                  id: hardware.id,
                                  stock: 9999,
                                  variants,
                              };
                          }
                          return {};
                      })
                    : [];

            const accessories = offer.accessories
                ? offer.accessories.map((accessory) => {
                      if (accessory) {
                          const variants = accessory.variants
                              ? accessory.variants.map((variant) => ({
                                    id: variant.ebootisId,
                                    stock: 9999,
                                }))
                              : [];

                          return {
                              id: accessory.id,
                              stock: 9999,
                              variants,
                          };
                      }
                      return {};
                  })
                : [];

            return {
                id: offer.offer_id,
                globalOfferId: offer.global_offer_id,
                stock: 9999,
                hardwares,
                accessories,
                o2Affiliate: offer.o2Affiliate,
            };
        });

        return {
            id: offerGroup.id,
            stock: 9999,
            offers,
        };
    });

    const data = { offerGroups: stockedOfferGroups };

    axios
        .post("/helper/get-stocks/", data)
        .then((response) => {
            if (
                response.status === 200 &&
                response.data.success &&
                response.data.stocks
            ) {
                // calculating stocks downwards ( from offerGroup to offer to hardware to variant )
                const calculatedStocks = response.data.stocks.map(
                    (offerGroup) => ({
                        id: offerGroup.id,
                        stock: offerGroup.stock,
                        offers: offerGroup.offers.map((offer) => ({
                            id: offer.id,
                            stock: Math.min(offerGroup.stock, offer.stock),
                            hardwares: offer.hardwares
                                ? offer.hardwares.map((hardware) => ({
                                      id: hardware.id,
                                      stock: Math.min(
                                          offerGroup.stock,
                                          offer.stock,
                                          hardware.stock
                                      ),
                                      variants: hardware.variants.map(
                                          (variant) => ({
                                              id: variant.id,
                                              stock: Math.min(
                                                  offerGroup.stock,
                                                  offer.stock,
                                                  hardware.stock,
                                                  variant.stock
                                              ),
                                          })
                                      ),
                                  }))
                                : [],
                            accessories: offer.accessories
                                ? offer.accessories.map((accessory) => ({
                                      id: accessory.id,
                                      stock: Math.min(
                                          offerGroup.stock,
                                          offer.stock,
                                          accessory.stock
                                      ),
                                      variants: accessory.variants.map(
                                          (variant) => ({
                                              id: variant.id,
                                              stock: Math.min(
                                                  offerGroup.stock,
                                                  offer.stock,
                                                  accessory.stock,
                                                  variant.stock
                                              ),
                                          })
                                      ),
                                  }))
                                : [],
                        })),
                    })
                );

                callback(calculatedStocks);
            }
        })
        .catch((error) => {});
};

export const mapOffersInGroups = (offersInGroups) =>
    offersInGroups.map((offerGroup) => {
        const offers = offerGroup.offers.map((offer) => {
            const hardwares =
                offer.hardwares && offer.hardwares.length
                    ? offer.hardwares.map((hardware) => {
                          if (hardware) {
                              const variants = hardware.variants
                                  ? hardware.variants.map((variant) => ({
                                        id: variant.ebootisId,
                                        stock: 9999,
                                    }))
                                  : [];

                              return {
                                  id: hardware.id,
                                  stock: 9999,
                                  variants,
                              };
                          }
                          return {};
                      })
                    : [];

            const accessories = offer.accessories
                ? offer.accessories.map((accessory) => {
                      if (accessory) {
                          const variants = accessory.variants
                              ? accessory.variants.map((variant) => ({
                                    id: variant.ebootisId,
                                    stock: 9999,
                                }))
                              : [];

                          return {
                              id: accessory.id,
                              stock: 9999,
                              variants,
                          };
                      }
                      return {};
                  })
                : [];

            return {
                id: offer.offer_id,
                stock: 9999,
                hardwares,
                accessories,
            };
        });

        return {
            id: offerGroup.id,
            stock: 9999,
            offers,
        };
    });

export const updateArrayRecursive = (currentArray, updateArray) => {
    const emptyTarget = (value) => (Array.isArray(value) ? [] : {});
    const clone = (value, options) => merge(emptyTarget(value), value, options);

    function legacyArrayMerge(target, source, options) {
        const destination = target.slice();

        source.forEach((e, i) => {
            if (typeof destination[i] === "undefined") {
                const cloneRequested = options.clone !== false;
                const shouldClone =
                    cloneRequested && options.isMergeableObject(e);
                destination[i] = shouldClone ? clone(e, options) : e;
            } else if (options.isMergeableObject(e)) {
                destination[i] = merge(target[i], e, options);
            } else if (target.indexOf(e) === -1) {
                destination.push(e);
            }
        });
        return destination;
    }

    return merge(currentArray, updateArray, { arrayMerge: legacyArrayMerge });
};

/**
 * This script expect ONE HARDWARE  in "hardwares"
 * @param offersInGroup
 * @returns {*}
 */

export const mapStocks = (offersInGroup) =>
    offersInGroup.map((offerGroup) => ({
        id: offerGroup.id,
        stock: offerGroup.stock,
        offers: get(offerGroup, ["offers"], []).map((offer) => {
            if (typeof offerGroup.coupon === typeof false) {
                return {
                    id: offer.id,
                    stock: Math.min(
                        offerGroup.stock,
                        offer.stock,
                        offer.hardwares[0].stock
                    ),
                    hardwares: offer.hardwares
                        ? offer.hardwares.map((hardware) => ({
                              id: hardware.id,
                              stock: Math.min(
                                  offerGroup.stock,
                                  offer.stock,
                                  hardware.stock
                              ),
                              variants: hardware.variants.map((variant) => ({
                                  id: variant.id,
                                  stock: Math.min(
                                      offerGroup.stock,
                                      offer.stock,
                                      hardware.stock,
                                      variant.stock
                                  ),
                              })),
                          }))
                        : [],
                    accessories: offer.accessories
                        ? offer.accessories.map((accessory) => ({
                              id: accessory.id,
                              stock: Math.min(
                                  offerGroup.stock,
                                  offer.stock,
                                  accessory.stock
                              ),
                              variants: accessory.variants.map((variant) => ({
                                  id: variant.id,
                                  stock: Math.min(
                                      offerGroup.stock,
                                      offer.stock,
                                      accessory.stock,
                                      variant.stock
                                  ),
                              })),
                          }))
                        : [],
                };
            }
            return offer;
        }),
    }));
