export const ADDRESS_STEP = 'address';
export const DELIVERY_STEP = 'delivery';
export const PAYMENT_STEP = 'payment';
export const PRIVACY_STEP = 'privacy';
export const EECC_STEP = 'eecc';

export const SALUTATION_FEMALE = 'female';
export const SALUTATION_MALE = 'male';

export const PAYMENT_TYPE_INVOICE = 'invoice';
export const PAYMENT_TYPE_PREPAYMENT = 'prepayment';
