export function isMobile(data) {
    if (data.xs === true || data.sm === true) {
        return true;
    }
    return false;
}

export function isXs(data) {
    return !!data.xs;
}

export function isSm(data) {
    return !!data.sm;
}

export function isMd(data) {
    return !!data.md;
}

export function isLg(data) {
    return !!data.lg;
}

export function isXl(data) {
    return !!data.xl;
}

export function isTouchDevice() {
    return (
        "ontouchstart" in window ||
        (window.DocumentTouch && document instanceof window.DocumentTouch)
    );
}

export function scrollInView() {
    window.location.hash = "";
    window.location.hash = "#breadcrumb";
}

export function getBreakpoint(mt) {
    return Object.keys(mt)[Object.values(mt).indexOf(true)];
}
