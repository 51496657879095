import { reducer as formReducer } from 'redux-form';
import AXIOS from 'axios';

import {
  FORM_PREVIOUSPROVIDER_COMPANY,
  FORM_PREVIOUSPROVIDER_SALUTATION_COMPANY,
  FORM_PREVIOUSPROVIDER_SALUTATION_HERR,
  GIFTCARD_HIGHLIGHT,
  PRODUCT_HIGHLIGHT,
  TARIFF_HIGHLIGHT
} from '@core/frontend/src/global/constants';

import {
  CHECKOUT_TYPE,
  CLOSE_STATIC,
  CONFIG_STEP_VISIBILITY,
  CONTRACT_PARTNER,
  EDIT_STEP,
  FINALIZE_COMPANY_AGREEMENT_FLOW,
  IDENTITY_TYPE,
  INIT_FORM_STATE,
  NEXT_STEP,
  OPEN_CP_MODAL,
  OPEN_STATIC,
  PREVIOUS_STEP,
  PROCEED_CHECKOUT,
  PROCEED_CHECKOUT_AFTER_EDIT,
  SET_BANK_NAME,
  SET_CITY_NAME,
  SET_CITY_NAME_DELIVERY,
  SET_PAYMENT_TYPE,
  SET_STREET_DROP_DOWN,
  SET_STREET_DROP_DOWN_DELIVERY,
  SET_SUBCARDS_COUNT,
  SET_VOUCHER_CODE,
  SET_ADDRESS_VALIDATED,
  SET_ACTIVE_ATTACHMENTS,
  SET_PRICE_GROSS,
  SHOW_END_OF_CONTRACT,
  SHOW_PROVIDER_FORM_AREA,
  START_COMPANY_AGREEMENT_FLOW,
  STEP_VISIBILITY,
  STORE_RESPONSE,
  SUPERSELECT_SECOND_SIM_ALLNETFLAT,
  TOGGLE_ACCORDION,
  TOGGLE_HIGHLIGHT,
  TOGGLE_INFOBOX,
  TOGGLE_PROVIDER_FORM,
  UNSET_BANK_NAME,
  UPDATE_TARIFF_BASIC_CHARGE,
  VALIDATION_BACKEND_FAILED,
  VISIBILITY_BIC_BLZ_FIELD,
  VISIBILITY_RESIDENCE_PERMIT,
  VISIBILITY_RESIDENCE_PERMIT_VALID_UNTIL,
  VISIBILITY_STEP_5,
  EECC_GENERATE_DOCUMENT,
  EECC_DOWNLOAD_DOCUMENT,
  GET_PDF_FILES_FROM_PROVIDER,
  USER_HAS_GOT_THE_PDF_FILE,
  SEND_MAIL_TO_PROVIDER,
} from '@core/frontend/src/global/types';

export const initialState = {
  model: {
    nextStepPendingToProceed: 1,
    editMode: [true, false, false, false, false],
    touched: [false, false, false, false, false], // apply when already was proceeded at least once.
    stepsProceeded: [false, false, false, false, false],
    contractPartnerModal: {
      cpModal1: false,
      cpModal2: false,
      cpModalInfo: false,
      cpModalAge: false,
      cpModalPersonaId: false,
      sessionExpired: false,
      networkError: false,
      webidError: false,
      shoppingCartDeliveryModal: false,
      shoppingCartGiftCardModal: false,
      cartGiftCardDetailsModal: false,
      webidNotAvailableModal: false,
      hardwareDetails: false,
      tariffDetails: false,
      giftCardDetails: false,
      accessoriesDetails: false,
      contractExtension: false,
      tipForContractNoTerminated: false,
      tipForPrepaidContractNoTerminated: false,
      providerInfo: false,
      voucherCodeInfo: false,
      deliveryCosts: false,
      deliveryWebID: false,
      deliveryDHL: false
    },
    openStatic: null, // from Mirko dynamic component. Contains the current open modal (content loaded by ajax)
    controlFormFlow: {
      nationalities: [],
      residencePermits: [],
      providers: [],
      carriers: [],
      mnpNetPrefixes: [],
      cities: [],
      streets: [],
      citiesDelivery: [],
      streetsDelivery: [],
      hardwareDetails: undefined,
      tariffDetails: undefined,
      giftCardDetails: undefined,
      accessoriesDetails: undefined,
      terminationAgreementInfoBox: false,
      terminationPrepaidAgreementInfoBox: false,
      prepaidInfoBox: false,
      visibilityResidencePermit: false, // shows residencePermit dropDown
      visibilityResidencePermitValidUntil: false, // shows residencePermitValidUntil input field
      visibilityStep5: false, // TODO not dynamic !!! move out
      visibilityEndOfContract: false,
      identificationType: undefined,
      showBicBlzField: { type: 'none', show: false },
      previousProviderForm: null,
      showPreviousProviderForm: {
        active: false,
        activeArea: '' // values: [empty='' | private | company]
      },
      accordion: [false, false, false, false, false],
      stepVisibility: [false, false, false, false, false, false], // preset visibilty - > config in
      [GIFTCARD_HIGHLIGHT]: false,
      [PRODUCT_HIGHLIGHT]: false,
      [TARIFF_HIGHLIGHT]: false,
      subCardsCount: 0,
      superselectSecondSimAllnetFlat: false,
      contractPartner: 'S-KON eKontor 24 GmbH',
      checkoutType: 'default'
    },
    eeccIsLoading: false,
    eeccAccepted: false,
    eeccSuccess: true,
    eeccPdfFile: undefined,
    eeccPdfFileMk: undefined,
    paymentForm: "paypal",
    addressValidated: true,
    activeAttachments: [],
    hardwarePriceInclAttachmentsPrice: {}, 	
  }
};

/* helpers functions. */
const postActiveAttachments = async (activeAttachments) => {
  try {
    const response = await AXIOS.post('/checkout/addAttachments/', JSON.stringify(activeAttachments), {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (response.status !== 200) {
        throw new Error('Network response was not ok');
    }

    return response.data;

  } catch (error) {
      console.error('There was an error!', error);
  }
}

const findMatch = (index, step) => index === Number(step) - 1;

// switch found value to the inverted one.
const setAsProceeded = (value, index, step) => (findMatch(index, step)
  ? !value
  : value);

// set given step to true. Others switch to false
const setAsEditable = (index, step) => findMatch(index, step);

// return always true for given value, otherwise the value whatever it was
const setAsTouched = (value, index, step) => (findMatch(index, step)
  ? true
  : value);

// return always false for given value, otherwise the value whatever it was
const setAsUnProceeded = (value, index, step) => (findMatch(index, step)
  ? false
  : value);

const modelReducer = (model = {}, action = {}) => {
  switch (action.type) {
    case PROCEED_CHECKOUT_AFTER_EDIT:
      model.nextStepPendingToProceed = Number(action.data) + 1;
      return {
        ...model,
        stepsProceeded: model.stepsProceeded.map(
          (v, i) => setAsProceeded(v, i, action.data)
        ),
        touched: model.touched.map((v, i) => setAsTouched(v, i, action.data)),
        editMode: model.editMode.map(
          (v, i) => setAsEditable(i, model.nextStepPendingToProceed)
        )
      };
    case PROCEED_CHECKOUT:
      return {
        ...model,
        stepsProceeded: model.stepsProceeded.map(
          (v, i) => setAsProceeded(v, i, action.data)
        ),
        touched: model.touched.map((v, i) => setAsTouched(v, i, action.data)),
        nextStepPendingToProceed: model.nextStepPendingToProceed + 1

      };
    case NEXT_STEP:
      return {
        ...model,
        editMode: model.editMode.map((v, i) => setAsEditable(i, action.data))
      };

    case PREVIOUS_STEP:
      return {
        ...model,
        editMode: action.historyState.editMode,
        touched: action.historyState.touched,
        stepsProceeded: action.historyState.stepsProceeded,
        nextStepPendingToProceed: action.historyState.nextStepPendingToProceed
      };
    case EDIT_STEP:
      const editMode = model.editMode.map(
        (v, i) => setAsEditable(i, action.data)
      );
      const stepsProceeded = model.stepsProceeded.map((v, i) => {
        model.stepsProceeded[i] = action.data > i;
        v = model.stepsProceeded[i];
        return setAsUnProceeded(v, i, action.data);
      });
      const touched = model.touched.map((v, i) => {
        model.touched[i] = action.data > i;
        v = model.touched[i];
        return setAsTouched(v, i, action.data);
      });

      return {
        ...model,
        editMode,
        stepsProceeded,
        touched,
        nextStepPendingToProceed: Number(action.data)
      };
    case INIT_FORM_STATE:
      return {
        ...model,
        editMode: action.formState.editMode,
        touched: action.formState.touched,
        stepsProceeded: action.formState.stepsProceeded,
        nextStepPendingToProceed: action.formState.nextStepPendingToProceed
      };
    case OPEN_CP_MODAL:
      return {
        ...model,
        contractPartnerModal: {
          ...model.contractPartnerModal,
          [action.modal]: action.data
        }
      };
    case STORE_RESPONSE:
      return {
        ...model,
        controlFormFlow: ((key, data) => ({
          ...model.controlFormFlow,
          [key]: data
        }))(action.key, action.data)
      };
    case VALIDATION_BACKEND_FAILED:
      return {
        ...model,
        controlFormFlow: ((key, data) => ({
          ...model.controlFormFlow,
          [key]: data
        }))('formErrors', action.data)
      };
    case TOGGLE_PROVIDER_FORM:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          showPreviousProviderForm: {
            ...model.controlFormFlow.showPreviousProviderForm,
            active: !model.controlFormFlow.showPreviousProviderForm.active
          }
        }
      };
    case SHOW_PROVIDER_FORM_AREA:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          showPreviousProviderForm: {
            ...model.controlFormFlow.showPreviousProviderForm,
            active: true,
            activeArea: action.area
          }
        }
      };
    case TOGGLE_INFOBOX:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          [action.infoBox]: action.state
        }
      };
    case START_COMPANY_AGREEMENT_FLOW:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          showPreviousProviderForm: {
            ...model.controlFormFlow.showPreviousProviderForm,
            active: true,
            activeArea: FORM_PREVIOUSPROVIDER_COMPANY
          }
        }
      };
    case FINALIZE_COMPANY_AGREEMENT_FLOW:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          showPreviousProviderForm: {
            ...model.controlFormFlow.showPreviousProviderForm,
            active: false,
            activeArea: ''
          }
        }
      };
    case VISIBILITY_RESIDENCE_PERMIT:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          visibilityResidencePermit: action.data
        }
      };
    case VISIBILITY_RESIDENCE_PERMIT_VALID_UNTIL:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          visibilityResidencePermitValidUntil: action.data
        }
      };
    case VISIBILITY_STEP_5:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          visibilityStep5: action.value
        }
      };
    // new !! set visibilty of steps
    case STEP_VISIBILITY:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          stepVisibility: model.controlFormFlow.stepVisibility.map((v, i) => {
            if (action.value === i + 1) {
              return !v;
            }
            return v;
          })
        }
      };

    case CONFIG_STEP_VISIBILITY:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          stepVisibility: action.value
        }
      };

    case TOGGLE_ACCORDION:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          accordion: model.controlFormFlow.accordion.map(
            (v, i) => setAsEditable(i, action.stepId)
          )
        }
      };
    case TOGGLE_HIGHLIGHT:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          [action.product]: (typeof action.highlight !== 'undefined'
            ? action.highlight : !model.controlFormFlow[action.product])
        }
      };
    case OPEN_STATIC:
      return {
        ...model,
        openStatic: action.data
      };
    case CLOSE_STATIC:
      return {
        ...model,
        openStatic: null
      };
    case SHOW_END_OF_CONTRACT:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          visibilityEndOfContract: action.show
        }
      };
    case IDENTITY_TYPE:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          identificationType: action.data
        }
      };
    case SET_CITY_NAME:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          cities: action.data
        }
      };
    case SET_STREET_DROP_DOWN:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          streets: action.data
        }
      };
    case SET_CITY_NAME_DELIVERY:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          citiesDelivery: action.data
        }
      };
    case SET_STREET_DROP_DOWN_DELIVERY:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          streetsDelivery: action.data
        }
      };

    case VISIBILITY_BIC_BLZ_FIELD:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          showBicBlzField: action.data
        }
      };
    case SET_VOUCHER_CODE:
      return {
        ...model,
        voucherCode: action.data
      };

    case CONTRACT_PARTNER:
      return {
        ...model,
        contractPartner: action.data
      };
    case CHECKOUT_TYPE:
      return {
        ...model,
        checkoutType: action.data
      };

    case UPDATE_TARIFF_BASIC_CHARGE:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          subCardsCount: action.data.amount
        },
        tariff: {
          ...model.tariff,
          monthlyPrice: action.data.value,
          monthlyprice: action.data.value,
          subCardsCount: action.data.amount
        }
      };

    case SET_SUBCARDS_COUNT:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          subCardsCount: action.data.amount
        }
      };

    case SUPERSELECT_SECOND_SIM_ALLNETFLAT:
      return {
        ...model,
        controlFormFlow: {
          ...model.controlFormFlow,
          superselectSecondSimAllnetFlat: action.data
        }
      };

    case EECC_GENERATE_DOCUMENT:
      return {
        ...model,
        eeccIsLoading: action.data.eeccIsLoading,
        eeccAccepted: action.data.eeccAccepted,
        eeccSuccess: action.data.eeccSuccess,
        eeccPdfFile: action.data.eeccPdfFile,
        eeccPdfFileMk: action.data.eeccPdfFileMk,
      }

    case EECC_DOWNLOAD_DOCUMENT:
      return {
        ...model,
        eeccIsLoading: action.data.eeccIsLoading,
        eeccAccepted: action.data.eeccAccepted,
        eeccSuccess: action.data.eeccSuccess,
        eeccPdfFile: action.data.eeccPdfFile,
        eeccPdfFileMk: action.data.eeccPdfFileMk,
      }

    case SEND_MAIL_TO_PROVIDER:
      return {
        ...model,
        eeccAccepted: action.data.eeccAccepted,
        eeccIsLoading: action.data.eeccIsLoading,
        eeccSuccess: action.data.eeccSuccess
      };

    case GET_PDF_FILES_FROM_PROVIDER:
      return {
        ...model,
        eeccAccepted: action.data.eeccAccepted,
        eeccIsLoading: action.data.eeccIsLoading,
        eeccSuccess: action.data.eeccSuccess,
        eeccPdfFile: action.data.eeccPdfFile,
        eeccPdfFileMk: action.data.eeccPdfFileMk,
      };

    case USER_HAS_GOT_THE_PDF_FILE:
      return {
        ...model,
        eeccAccepted: action.data.eeccAccepted,
        eeccIsLoading: action.data.eeccIsLoading,
        eeccSuccess: action.data.eeccSuccess,
      };

    case SET_PAYMENT_TYPE:
      return {
        ...model,
        paymentForm: action.data
      };

    case SET_ADDRESS_VALIDATED:
      return {
        ...model,
        addressValidated: action.data
      }

    case SET_ACTIVE_ATTACHMENTS:
      return {
        ...model,
        activeAttachments: action.payload,
      };

    case SET_PRICE_GROSS:
      return {
        ...model,
        hardwarePriceInclAttachmentsPrice: action.payload,
      };

    default: {
      return model;
    }
  }
};

export default function reducer(state = initialState, action = {}) {
  // const pageModel = pageReducer(state.model, action);
  const checkoutModel = modelReducer(state.model, action);

  return {
    model: checkoutModel,
    form: formReducer.plugin({
      addressForm: (state, action) => {
        switch (action.type) {
          case SET_CITY_NAME:
            if (action.data.length === 1) {
              return {
                ...state,
                values: {
                  ...state.values,
                  town: action.data[0]
                }
              };
            }
            return state;

          case SET_CITY_NAME_DELIVERY:
            if (action.data.length === 1) {
              return {
                ...state,
                values: {
                  ...state.values,
                  deliveryTown: action.data[0]
                }
              };
            }
            return state;

          default:
            return state;
        }
      },
      bankAccountForm: (state, action) => {
        switch (action.type) {
          case SET_BANK_NAME:
            return {
              ...state,
              values: {
                ...state.values,
                bank: action.data.name
              }
            };
          case UNSET_BANK_NAME:
            return {
              ...state,
              values: {
                ...state.values,
                bank: ''
              }
            };
          default:
            return state;
        }
      },
      mnpForm: (state, action) => {
        switch (action.type) {
          case START_COMPANY_AGREEMENT_FLOW:
            return {
              ...state,
              values: {
                ...state.values,
                previousProvider: true,
                previousProviderSalutation: FORM_PREVIOUSPROVIDER_SALUTATION_COMPANY
              }
            };
          case FINALIZE_COMPANY_AGREEMENT_FLOW:
            return {
              ...state,
              values: {
                ...state.values,
                previousProvider: false,
                previousProviderSalutation: ''
              }
            };
          case SHOW_PROVIDER_FORM_AREA:
            return {
              ...state,
              values: {
                ...state.values,
                previousProvider: true,
                previousProviderSalutation: (action.area === FORM_PREVIOUSPROVIDER_COMPANY)
                    ? FORM_PREVIOUSPROVIDER_SALUTATION_COMPANY : FORM_PREVIOUSPROVIDER_SALUTATION_HERR
              }
            };
          default:
            return state;
        }
      }
    })(state.form, action)
  };
}
