import throttle from "lodash/throttle";
import transform from "lodash/transform";

import { calculateResponsiveState } from "../actions/page";

export function addResponsiveHandlers(store, throttleTime = 100) {
    const throttledHandler = throttle(
        () =>
            store.dispatch(
                calculateResponsiveState(
                    typeof window === "undefined" ? {} : window
                )
            ),
        throttleTime
    );
    throttledHandler();
    if (typeof window !== "undefined") {
        window.addEventListener("resize", () => throttledHandler());
    }
    return store;
}

export function getMediaType(matchMedia, mediaQueries) {
    if (typeof matchMedia === "undefined") {
        return "";
    }
    return transform(mediaQueries, (result, query, type) => {
        result[type] = !!matchMedia(query).matches;
    });
}
