import axios from "axios";

export const getBankName = (iban, bic) =>
  axios.post("/checkout/bank-name", {
    iban,
    bic,
  });

export default {
  getBankName,
};

export const calculateTotalPrice = (product, tariff, voucherCode) => {
  const shipping =
    product?.delivery_price?.postident ||
    product?.delivery_price?.webid ||
    tariff?.delivery_price?.postident ||
    tariff?.delivery_price?.webid;

  const price = product.price
    ? parseFloat(product.price.replace(",", "."))
    : null || 0;

  const debitPrice = product.debitPrice
    ? parseFloat(product.debitPrice.replace(",", "."))
    : null;

  const selectPriceToUse = () => {
    if(debitPrice) return debitPrice;
    return price;
  }

  const deliveryPrice = parseFloat(shipping.replace(",", "."));

  const voucherDiscount = voucherCode?.discount
    ? parseFloat(voucherCode.discount.replace(",", "."))
    : 0;

  const totalPrice = (selectPriceToUse() + deliveryPrice) - voucherDiscount;

  return totalPrice.toFixed(2);
};
