import { OPEN_STATIC, CLOSE_STATIC } from "../global/types";

export const initialState = {
    openStatic: null,
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case OPEN_STATIC:
            return {
                ...state,
                openStatic: action.data,
            };
        case CLOSE_STATIC:
            return {
                ...state,
                openStatic: null,
            };
        default:
            return state;
    }
};

export default reducer;
