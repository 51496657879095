export const ACCEPTED = 'ACCEPTED';

export const PROVIDER_KLARMOBIL = 'klarmobil';
export const PROVIDER_MD = 'freenet';
export const PROVIDER_O2 = 'o2';
export const PROVIDER_O2_CABLE = 'o2 Kabel';
export const PROVIDER_O2_DSL = 'o2 DSL';
export const PROVIDER_O2_NAME = 'Telefónica Germany GmbH & Co. OHG';
export const PROVIDER_TELEKOM = 'telekom';
export const PROVIDER_UNITYMEDIA = 'unitymedia';
export const PROVIDER_UNITYMEDIA_CABLE = 'Unitymedia Kabeltarife';
export const PROVIDER_VODAFONE = 'vodafone';
export const PROVIDER_VODAFONE_CABLE = 'Vodafone Kabel';
export const PROVIDER_VODAFONE_VOUCHER = 'Vodafone Mitnahmeprodukt';

/** Static Modal * */

export const DATA_SECURITY_MODAL = 'datasecurity';
export const DATA_SECURITY_COOKIES_MODAL = 'datasecurity-cookies';
export const TERMS_MODAL = 'terms';
export const TERMS_TELEFONICA_MODAL = 'terms-telefonica';
export const TERMS_MOBILCOM_MODAL = 'terms-mobilcom';
export const TERMS_WEBID_MODAL = 'terms-webid';
export const TERMS_SKY_MODAL = 'terms-sky';
export const TERMS_VODAFONE_MODAL = 'terms-vodafone';
export const TERMS_KLARMOBIL_MODAL = 'terms-klarmobil';
export const TERMS_UNITYMEDIA_MODAL = 'terms-unitymedia';
export const IMPRINT_MODAL = 'imprint';
export const WITHDRAWAL_MODAL = 'withdrawal';
export const PAYMENT_METHODS_MODAL = 'paymentMethods';

// Checkout Types
export const CHECKOUT_TYPE_HARDWARE = "smartphone";
export const CHECKOUT_TYPE_TABLET = "tablet";
// reducer
export const OPEN_STATIC = 'OPEN_STATIC';
export const CLOSE_STATIC = 'CLOSE_STATIC';

/** FAQ */

export const FAQ_PAGE_MENU = '0';
export const FAQ_PAGE_GENERAL = '1';
export const FAQ_PAGE_ACTIVATE = '2';
export const FAQ_PAGE_ORDER = '3';
export const FAQ_PAGE_RECLAMATION = '4';
export const FAQ_PAGE_OTHER = '5';

export const INIT_FAQ_MODEL = 'INIT_FAQ_MODEL';
export const INIT_ORDERSTATUS_MODEL = 'INIT_ORDERSTATUS_MODEL';

/** Checkout */

export const FORM_SALUTATION_HERR = 'Male'.toLowerCase();
export const FORM_SALUTATION_FRAU = 'Female'.toLowerCase();

export const FORM_PAYMENTADVANCE = 'prepayment'.toLowerCase();
export const FORM_PAYMENTPAYPAL = 'paypal'.toLowerCase();

export const FORM_PERSONAL_ID = 'personalId'.toLowerCase();
export const FORM_PASSPORT = 'passport'.toLowerCase();

export const FORM_PREVIOUSPROVIDER_SALUTATION_HERR = 'Male'.toLowerCase();
export const FORM_PREVIOUSPROVIDER_SALUTATION_FRAU = 'Female'.toLowerCase();
export const FORM_PREVIOUSPROVIDER_SALUTATION_COMPANY = 'Company'.toLowerCase();

export const FORM_PREVIOUSPROVIDER_PRIVATE = 'private'.toLowerCase();
export const FORM_PREVIOUSPROVIDER_COMPANY = 'company'.toLowerCase();

export const FORM_PREVIOUSPROVIDER_MONTHLY = 'monthly';
export const FORM_PREVIOUSPROVIDER_PREPAID = 'prepaid';

export const FORM_PREVIOUSPROVIDER_NOTYETTERMINED = 'notYetTermined'.toLowerCase();
export const FORM_PREVIOUSPROVIDER_NUMBERSTILLACTIVE = 'numberStillActive'.toLowerCase();
export const FORM_PREVIOUSPROVIDER_NUMBERNOTLONGERACTIVE = 'numberNotLongerActive'.toLowerCase();

export const TOO_OLD_FOR_STUDENT = 'TOO_OLD_FOR_STUDENT';
export const TOO_YOUNG_FOR_SENIOR = 'TOO_YOUNG_FOR_SENIOR';

/** Checkout model constants */

export const GIFTCARD_HIGHLIGHT = 'giftCardHighlight'.toLowerCase();
export const PRODUCT_HIGHLIGHT = 'productHighlight'.toLowerCase();
export const TARIFF_HIGHLIGHT = 'tariffHighlight'.toLowerCase();
export const VOUCHERCODE_HIGHLIGHT = 'voucherCodeHighlight'.toLowerCase();

/** Checkout errors */

export const EMPTY = 'EMPTY';
export const PACK_STATION = 'PACK_STATION';
export const EMPTY_IBAN = 'EMPTY_IBAN';
export const SHORT_FORMAT = 'SHORT_FORMAT';
export const WRONG_FORMAT = 'WRONG_FORMAT';
export const WRONG_FORMAT_MD = 'WRONG_FORMAT_MD';
export const WRONG_FORMAT_IBAN = 'WRONG_FORMAT_IBAN';
export const WRONG_FORMAT_BIC = 'WRONG_FORMAT_BIC';
export const NOT_MATCH = 'NOT_MATCH';
export const NO_NET_PREFIX = 'NO_NET_PREFIX';
export const NO_NET_PREFIX_O2 = 'NO_NET_PREFIX_O2';
export const TOO_YOUNG = 'TOO_YOUNG';
export const TOO_YOUNG18 = 'TOO_YOUNG18';
export const TOO_YOUNG16 = 'TOO_YOUNG16';
export const TOO_OLD = 'TOO_OLD';
export const TOO_SHORT = 'TOO_SHORT';
export const TOO_FAR_IN_THE_FUTURE = 'TOO_FAR_IN_THE_FUTURE';
export const SAME_PROVIDER = 'SAME_PROVIDER';
export const DATE_IN_THE_PAST = 'DATE_IN_THE_PAST';
export const DATE_TOO_FAR_IN_THE_FUTURE = 'DATE_TOO_FAR_IN_THE_FUTURE';
export const PORTABILITY_DATE_TOO_EARLY = 'PORTABILITY_DATE_TOO_EARLY';
export const PORTABILITY_DATE_TOO_LATE = 'PORTABILITY_DATE_TOO_LATE';
export const PORTABILITY_DATE_TOO_LATE_OTHER = 'PORTABILITY_DATE_TOO_LATE_OTHER';
export const WRONG_LENGTH = 'WRONG_LENGTH';
export const DOUBLE_STRING = 'DOUBLE_STRING';

export const SHARED_ERROR = 'SHARED_ERROR';
export const SHARED_ERROR_ZIP_STREET_NOT_MATCH = 'SHARED_ERROR_ZIP_STREET_NOT_MATCH';
export const SHARED_ERROR_ADDRESS_NOT_MATCH = 'SHARED_ERROR_ADDRESS_NOT_MATCH';
export const SHARED_ERROR_EMAIL_NOT_MATCH = 'SHARED_ERROR_EMAIL_NOT_MATCH';
export const SHARED_ERROR_BANK_ACCOUNT_NOT_MATCH = 'SHARED_ERROR_BANK_ACCOUNT_NOT_MATCH';
export const SHARED_ERROR_IBAN_NOT_MATCH = 'SHARED_ERROR_IBAN_NOT_MATCH';
export const SHARED_ERROR_NEEDS_RESIDENCY_PERMIT = 'SHARED_ERROR_NEEDS_RESIDENCY_PERMIT';

export const ADDITIONAL_SIMCARDS_MIN_VALUE = 'ADDITIONAL_SIMCARDS_MIN_VALUE';
export const ADDITIONAL_SIMCARDS_FALSE = 'ADDITIONAL_SIMCARDS_FALSE';

/** Page Not Found */

export const INIT_PAGE_NOT_FOUND_MODEL = 'INIT_PAGE_NOT_FOUND_MODEL';

/** CodeCeption */

export const CC_BTN_BACK = 'btnBack';
export const CC_BTN_CLEAR = 'btnClear';
export const CC_BTN_SUBMIT = 'btnSubmit';
export const CC_BTN_SUBMITDISABLED = 'btnSubmitDisabled';
export const CC_BTN_CLOSE = 'btnClose';
export const CC_BTN_OPEN = 'btnOpen';
export const CC_BTN_DETAILS = 'btnDetail';
export const CC_BTN_MORE = 'btnMore';
export const CC_BTN_ADDTOCART = 'btnAddToCart';
export const CC_BTN_EDIT = 'btnEdit';

/** LazyLoad Preload-Offset * */
export const LAZY_LOAD_OFFSET = 500;

/** Next-Buttons-Texts */
export const NEXT_ID = 'Weiter zu den Ausweisdaten';
export const NEXT_PAYMENT = 'Weiter zu den Bezahlmethoden';
export const NEXT_TARIFF = 'Weiter zu den Tarifangaben';
export const NEXT_MNP = 'Weiter zur Rufnummernmitnahme';
export const NEXT_DELIVERY = 'Weiter zur Lieferung';
export const NEXT_PRIVACY = 'Weiter zu Datenschutz & AGB';
export const NEXT_RECEPTION = 'Weiter zu Empfangsart';
export const NEXT_LAST = 'Weiter zum Bestellabschluss';
export const NEXT_EECC = 'Weiter zur Vertragszusammenfassung';

