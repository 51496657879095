import {
  FAQ_PAGE_MENU
} from '@core/frontend/src/global/constants';

export const initialState = {
  mobileFooterSectionOpen: null,
  openStatic: null,
  imprintOpen: false,
  datasecurityOpen: false,
  withdrawalOpen: false,
  termsOpen: false,
  deliveryInfo: false, // delivery info modal
  faq: false, // faq modal
  faqPage: FAQ_PAGE_MENU,
  faqContent: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_FOOTER_MODEL':
      return Object.assign({}, state, { ...DEFAULT_MODEL });
    case 'CHANGE_FOOTER_SECTION_OPEN':
      const section = action.data.section;
      return {
        ...state,
        mobileFooterSectionOpen: state.mobileFooterSectionOpen !== section
          ? section
          : null
      };
    case 'OPEN_STATIC':
      return {
        ...state,
        openStatic: action.data
      };
    case 'CLOSE_STATIC':
      return {
        ...state,
        openStatic: null
      };
    case 'HANDLE_MODAL':
      return {
        ...state,
        [action.key]: !state[action.key]
      };
    case 'FAQ_GO_TO_PAGE':
      return {
        ...state,
        faqPage: action.page
      };
    case 'FAQ_PROCESS_RESPONSE':
      return {
        ...state,
        faqContent: action.data
      };
    default:
      return state;
  }
};

export default reducer;
