import axios from "axios";

import {
    mapOffersInGroups,
    mapStocks,
    updateArrayRecursive,
} from "../global/helper/getStocks";

import { handleAjaxError } from "./checkout";

const SET_OFFERID = "SET_OFFERID";
const SET_VARIANT = "SET_VARIANT";
const SHOW_VARIANT = "SHOW_VARIANT";
const SET_CARRIER = "SET_CARRIER";
const SET_MANUFACTURER = "SET_MANUFACTURER";
const SET_SELECTED_TARIFF = "SET_SELECTED_TARIFF";
const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";
const SHOW_TARIFF = "SHOW_TARIFF";
const OPEN_TARIFF_MODAL = "OPEN_TARIFF_MODAL";
const CLOSE_TARIFF_MODAL = "CLOSE_TARIFF_MODAL";
const GET_MODAL_CONTENT = "GET_MODAL_CONTENT";
const SWITCH_ADDITION = "SWITCH_ADDITION";
const SET_HISTORY_STATE = "SET_HISTORY_STATE";
const OPEN_DELIVERY_MODAL = "OPEN_DELIVERY_MODAL";
const CLOSE_DELIVERY_MODAL = "CLOSE_DELIVERY_MODAL";
const SET_HARDWARE_DETAILS = "SET_HARDWARE_DETAILS";
const SET_TARIFF_DETAILS = "SET_TARIFF_DETAILS";
const SET_GENERAL_HARDWARE_DETAILS = "SET_GENERAL_HARDWARE_DETAILS";
const SET_DETAILS_VISIBILITY = "SET_DETAILS_VISIBILITY";
const SET_FAMILYFILTER = "SET_FAMILYFILTER";
const SET_OFFER = "SET_OFFER";
const SET_PAGEOFFER = "SET_PAGEOFFER";
const SET_HOVER_PAGEOFFER = "SET_HOVER_PAGEOFFER";
const SET_ACCESSORY = "SET_ACCESSORY";
const SET_ACCESSORY_SELECTED_VARIANT = "SET_ACCESSORY_SELECTED_VARIANT";
const SET_ACCESSORY_VARIANT_NEW = "SET_ACCESSORY_VARIANT_NEW";
const SET_ACCESSORY_HOVER_VARIANT = "SET_ACCESSORY_HOVER_VARIANT";
const SET_HARDWARE_DETAILLIST = "SET_HARDWARE_DETAILLIST";
const SET_TARIFF_DETAILLIST = "SET_TARIFF_DETAILLIST";
const UPDATE_STOCKS = "UPDATE_STOCKS";
const SET_IN_PATH_VALUE = "SET_IN_PATH_VALUE";
const SET_PRODUCT = "SET_PRODUCT";

const SET_PRODUCT_SELECTED = "SET_PRODUCT_SELECTED";
const SET_HARDWARE_VARIANT = "SET_HARDWARE_VARIANT";
const SET_PRODUCT_HOVER_COLOR = "SET_PRODUCT_HOVER_COLOR";
const SET_ACCESSORY_COLOR = "SET_ACCESSORY_COLOR";
const SET_ACCESSORY_HOVER_COLOR = "SET_ACCESSORY_HOVER_COLOR";
const SET_HOVER_HARDWARE_VARIANT = "SET_HOVER_HARDWARE_VARIANT";
const ADD_PRODUCT = "ADD_PRODUCT";
const UPDATE_STOCKS_LP = "UPDATE_STOCKS_LP";

export function init() {
    return (dispatch) => {
        dispatch({
            type: "INIT_PRODUCTDETAILS_MODEL",
        });
    };
}

export const setProductSelected = (product) => ({
    type: SET_PRODUCT_SELECTED,
    data: product,
});
export const addProduct = (product) => ({
    type: ADD_PRODUCT,
    data: product,
});
export const setHardwareVariant = (productId, variant) => ({
    type: SET_HARDWARE_VARIANT,
    data: { productId, variant },
});
export const setHardwareHoverVariant = (productId, variant) => ({
    type: SET_HOVER_HARDWARE_VARIANT,
    data: { productId, variant },
});
export const setProductHoverColor = (id, ebootisId) => ({
    type: SET_PRODUCT_HOVER_COLOR,
    data: { [id]: ebootisId },
});
export const setAccessoryColor = (id, ebootisId) => ({
    type: SET_ACCESSORY_COLOR,
    data: { [id]: ebootisId },
});
export const setAccessoryHoverColor = (id, ebootisId) => ({
    type: SET_ACCESSORY_HOVER_COLOR,
    data: { [id]: ebootisId },
});
export const setProduct = (id) => ({
    type: SET_PRODUCT,
    data: { [id]: { id } },
});
export const setInPathValue = (path, value) => ({
    type: SET_IN_PATH_VALUE,
    data: { path, value },
});
export const setOfferId = (offer_id) => ({
    type: SET_OFFERID,
    data: offer_id,
});

export const setOffer = (offer) => ({
    type: SET_OFFER,
    data: offer,
});

export const setVariant = (variant) => ({
    type: SET_VARIANT,
    data: variant,
});

export const showVariant = (variant, hoverEl) => ({
    type: SHOW_VARIANT,
    data: { variant, hoverEl },
});

export const setCarrier = (carrier) => ({
    type: SET_CARRIER,
    data: carrier,
});

export const setManufacturer = (manufacturer) => ({
    type: SET_MANUFACTURER,
    data: manufacturer,
});

export const setSelectedTariff = (tariff) => ({
    type: SET_SELECTED_TARIFF,
    data: tariff,
});

export const setSelectedProduct = (hardware, variant) => ({
    type: SET_SELECTED_PRODUCT,
    data: { hardware, variant },
});

export const showTariff = (tariff) => ({
    type: SHOW_TARIFF,
    data: tariff,
});

export const openTariffModal = () => ({
    type: OPEN_TARIFF_MODAL,
    data: true,
});

export const closeDeliveryModal = () => ({
    type: CLOSE_DELIVERY_MODAL,
    data: false,
});

export const openDeliveryModal = () => ({
    type: OPEN_DELIVERY_MODAL,
    data: true,
});

export const closeTariffModal = () => ({
    type: CLOSE_TARIFF_MODAL,
    data: false,
});

export const loadModalContent = () => {
    // to do => richtige URL
    const url = "....";
    const request = axios.get(url);

    return {
        type: GET_MODAL_CONTENT,
        payload: request,
    };
};

export const switchAddition = (value) => ({
    type: SWITCH_ADDITION,
    data: value,
});

export function setHistoryState(stateObj) {
    return {
        type: SET_HISTORY_STATE,
        data: stateObj,
    };
}

export const setTariffDetails = (tariff) => ({
    type: SET_TARIFF_DETAILS,
    data: tariff,
});

export const setHardwareDetails = (hardware) => ({
    type: SET_HARDWARE_DETAILS,
    data: hardware,
});

export const setGeneralHardwareDetails = (details) => ({
    type: SET_GENERAL_HARDWARE_DETAILS,
    data: details,
});

export const setDetailsVisibility = () => ({
    type: SET_DETAILS_VISIBILITY,
});

export const setFamilyfilter = (value) => ({
    type: SET_FAMILYFILTER,
    data: value,
});

export const setPageoffer = (value) => ({
    type: SET_PAGEOFFER,
    data: value,
});

export const setHoverPageoffer = (value) => ({
    type: SET_HOVER_PAGEOFFER,
    data: value,
});

export const setAccessory = (value) => ({
    type: SET_ACCESSORY,
    data: value,
});

export const setAccessorySelectedVariant = (value) => ({
    type: SET_ACCESSORY_SELECTED_VARIANT,
    data: value,
});
export const setAccessoryVariant = (productId, accessoryId, variant) => ({
    type: SET_ACCESSORY_VARIANT_NEW,
    data: { productId, accessoryId, variant },
});
export const setAccessoryHoverVariant = (productId, accessoryId, variant) => ({
    type: SET_ACCESSORY_HOVER_VARIANT,
    data: { productId, accessoryId, variant },
});
export const setHardwareDetaillist = (value) => ({
    type: SET_HARDWARE_DETAILLIST,
    data: value,
});

export const setTariffDetaillist = (value) => ({
    type: SET_TARIFF_DETAILLIST,
    data: value,
});

export const updateStocks = (value) => ({
    type: UPDATE_STOCKS,
    data: value,
});

export function toggleFilterMenu() {
    return {
        type: "TOGGLE_FILTER_MENU",
    };
}

export function changeMobileFilterCategory(category) {
    return {
        type: "CHANGE_MOBILE_FILTER_CATEGORY",
        data: { category },
    };
}

export function addFilter(group, value) {
    return {
        type: "ADD_FILTER",
        data: { group, value },
    };
}

export function removeFilter(group, value) {
    return {
        type: "REMOVE_FILTER",
        data: { group, value },
    };
}

export function deleteFilters() {
    return {
        type: "DELETE_FILTERS",
        data: { filters: [] },
    };
}

export function updateFilter(group, value) {
    return {
        type: "UPDATE_FILTER",
        data: { group, value },
    };
}

export function addnFilterCandidate(group, value) {
    return {
        type: "ADD_FILTER_CANDIDATE",
        data: { group, value },
    };
}

export function addnFilterRemoveCandidate(group, value) {
    return {
        type: "ADD_FILTERREMOVE_CANDIDATE",
        data: { group, value },
    };
}

export function removenFilterCandidate(group, value) {
    return {
        type: "REMOVE_FILTERCANDIDATE",
        data: { group, value },
    };
}

export function removenFilterRemoveCandidate(group, value) {
    return {
        type: "REMOVE_FILTERCANDIDATE2REMOVE",
        data: { group, value },
    };
}

export function setLandingPageData(data) {
    return {
        type: "SET_LANDINGPAGE_DATA",
        data: data.landingPageData,
    };
}

export function setLandingPageProductGridData(data) {
    return {
        type: "SET_LANDINGPAGE_PRODUCT_GRID_DATA",
        data,
    };
}

export const setLandingPageStocks = (data) => ({
    type: "SET_LANDINGPAGE_STOCKS",
    data,
});
export const updateStocksLp = (data) => ({
    type: UPDATE_STOCKS_LP,
    data,
});
export const fetchStocks = (offersInGroups) => {
    const url = "/helper/get-stocks/";
    return (dispatch) => {
        const mappedData = mapOffersInGroups(offersInGroups);
        try {
            axios.post(url, { offerGroups: mappedData }).then(
                (response) => {
                    let updateData = response.data.stocks
                        ? mapStocks([...response.data.stocks])
                        : [];
                    updateData = updateArrayRecursive(
                        offersInGroups,
                        updateData
                    );
                    dispatch(
                        setLandingPageProductGridData({
                            landingPageProductGridData: [...updateData],
                        })
                    );
                },
                (error) => {
                    // manage error in response
                    dispatch(handleAjaxError(error));
                }
            );
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setOffers = (data) => ({
    type: "SET_OFFERS",
    data,
});

export const setDslOffers = (data) => ({
    type: "SET_DSL_OFFERS",
    data,
});

export function setLandingpageName(payload) {
    return {
        type: "SET_LANDINGPAGE_NAME",
        payload,
    };
}

