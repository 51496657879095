import MediaQuery from "mediaquery";
import { getMediaType } from "../support/responsive-utils";

const GET_SECTIONS_RESPONSE = "getSectionsResponse";

// TODO: define breakpoints from o2 responsive doc
const BREAKPOINTS = {
    xs: 509,
    sm: 749,
    md: 989,
    lg: 1229,
    xl: Infinity,
};
const MEDIA_QUERIES = MediaQuery.asObject(BREAKPOINTS);

export const initialState = {
    browser: {},
    deliverySettings: {},
    hotlines: {},
    pageData: {},
};

const pageReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case "CHANGE_DEVICE": {
            const device = action.data;
            return Object.assign({}, state, {
                device,
            });
        }
        case "CALCULATE_RESPONSIVE_STATE": {
            const browser = {
                mediaType: getMediaType(action.matchMedia, MEDIA_QUERIES),
            };

            return Object.assign({}, state, { browser });
        }
        case GET_SECTIONS_RESPONSE:
            if (action.success) {
                const currentSections = [...state.pageData.sections];
                for (let i = 0; i < action.payload.length; i++) {
                    const sectionWithData = action.payload[i];
                    currentSections.some((el, index) => {
                        if (el._id === sectionWithData._id) {
                            currentSections[index] = sectionWithData;
                        }
                    });
                }
                return {
                    ...state,
                    pageData: {
                        ...state.pageData,
                        sections: currentSections,
                    },
                };
            }
            return {
                ...state,
            };
        default: {
            return state;
        }
    }
};

export default pageReducer;
