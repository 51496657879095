import React from 'react';

const Item = props => (
  <li className={[(props.active ? 'active' : ''), props.className].join(' ')}>

    {props.active ? (
      <span>{props.text}</span>
    ) : (
      <a href={props.url}>
        {props.children ? props.children : (
          <span>{props.text}</span>
        )}
      </a>
    )}
    <i className="icon-MM_iconfont_ArrowR" />
  </li>
);

export default Item;
