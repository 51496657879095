export const GET_CHECKOUT_ADDRESS_DATA = 'GET_CHECKOUT_ADDRESS_DATA';
export const SET_CHECKOUT_ADDRESS_DATA = 'SET_CHECKOUT_ADDRESS_DATA';
export const SET_DSL_CHECKOUT_DATA = 'SET_DSL_CHECKOUT_DATA';
export const GET_CHECKOUT_DELIVERY_ADDRESS_DATA = 'GET_CHECKOUT_DELIVERY_ADDRESS_DATA';
export const SET_CHECKOUT_DELIVERY_ADDRESS_DATA = 'SET_CHECKOUT_DELIVERY_ADDRESS_DATA';

export function getCheckoutAddressData(payload) {
  return {
    type: GET_CHECKOUT_ADDRESS_DATA,
    payload
  };
}

export function setCheckoutAddressData(payload) {
  return {
    type: SET_CHECKOUT_ADDRESS_DATA,
    payload
  };
}

export function setDslCheckoutData(payload) {
  return {
    type: SET_DSL_CHECKOUT_DATA,
    payload
  };
}

export function getCheckoutDeliveryAddressData(payload) {
  return {
    type: GET_CHECKOUT_DELIVERY_ADDRESS_DATA,
    payload
  };
}

export function setCheckoutDeliveryAddressData(payload) {
  return {
    type: SET_CHECKOUT_DELIVERY_ADDRESS_DATA,
    payload
  };
}
