import { OPEN_STATIC, CLOSE_STATIC } from '@core/frontend/src/global/types';

export const initialState = {
  openStatic: null
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'OPEN_STATIC':
      return {
        ...state,
        openStatic: action.data
      };
    case 'CLOSE_STATIC':
      return {
        ...state,
        openStatic: null
      };
    case 'CHANGE_INPUT':
      return {
        ...state,
        form: {
          ...state.form,
          [action.data.label]: action.data.value
        }
      };
    default:
      return state;
  }
};

export default reducer;
