import React from "react";

/**
 *
 * PAGES
 *
 * */

const Landingpages = React.lazy(() => import("./landingpages/index.js"));
const PageNotFound = React.lazy(() => import("./pagenotfound/PageNotFound.js"));

const Rejection = React.lazy(() => import("@core/frontend/src/rejection/Rejection.js"));

const TariffOverview = React.lazy(() =>
    import("./tariffoverview/TariffOverview")
);

// Tablet/Smartphone overview
const Overview = React.lazy(() => import("./overview/Overview"));

const FAQ = React.lazy(() => import("./faq/Faq"));

const Campaign = React.lazy(() => import("./campaign/Campaign"));

const Orderconfirmation = React.lazy(() =>
    import("./orderconfirmation/Orderconfirmation")
);

const Revocation = React.lazy(() => import("./revocation/Revocation"));

const CheckoutDefault = React.lazy(() => import("./checkout/checkoutDefault/Checkout"));

const CheckoutDsl = React.lazy(() => import("./modules/Checkout/DSLCheckout"));

const CheckoutEnergy = React.lazy(() =>
    import("./modules/Checkout/EnergyCheckout/index")
);

const CheckoutO2Affiliate = React.lazy(() =>
    import("./checkout/checkout_O2affiliate/Checkout")
);

export default {
    Landingpages,
    PageNotFound,
    Rejection,
    TariffOverview,
    Overview,
    FAQ,
    Campaign,
    Orderconfirmation,
    Revocation,
    CheckoutDefault,
    CheckoutDsl,
    CheckoutEnergy,
    CheckoutO2Affiliate,
};
