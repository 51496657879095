export const INIT_CHECKOUT_MODEL = "INIT_CHECKOUT_MODEL";
export const CHANGE_INPUT = "CHANGE_INPUT";
export const PROCEED_CHECKOUT = "PROCEED_CHECKOUT";
export const PROCEED_CHECKOUT_AFTER_EDIT = "PROCEED_CHECKOUT_AFTER_EDIT";
export const VALIDATE_STEP = "VALIDATE_STEP";
export const NEXT_STEP = "NEXT_STEP";
export const EDIT_STEP = "EDIT_STEP";
export const OPEN_CP_MODAL = "OPEN_CP_MODAL";
export const GET_NATIONALITIES_DATA = "GET_NATIONALITIES_DATA";
export const GET_RESIDENCE_PERMITS_DATA = "GET_RESIDENCE_PERMITS_DATA";
export const GET_PROVIDERS_DATA = "GET_PROVIDERS_DATA";
export const GET_NETWORKS_DATA = "GET_NETWORKS_DATA";
export const GET_BANK_DATA = "GET_BANK_DATA";
export const STORE_RESPONSE = "STORE_RESPONSE";
export const SET_BANK_NAME = "SET_BANK_NAME";
export const UNSET_BANK_NAME = "UNSET_BANK_NAME";
export const VALIDATION_BACKEND_FAILED = "VALIDATION_BACKEND_FAILED";
export const SET_CITY_NAME = "SET_CITY_NAME";
export const SET_STREET_DROP_DOWN = "SET_STREET_DROP_DOWN";
export const SET_CITY_NAME_DELIVERY = "SET_CITY_NAME_DELIVERY";
export const SET_STREET_DROP_DOWN_DELIVERY = "SET_STREET_DROP_DOWN_DELIVERY";
export const TOGGLE_PROVIDER_FORM = "TOGGLE_PROVIDER_FORM";
export const SHOW_PROVIDER_FORM_AREA = "SHOW_PROVIDER_FORM_AREA";
export const TOGGLE_INFOBOX = "TOGGLE_INFOBOX";
export const START_COMPANY_AGREEMENT_FLOW = "START_COMPANY_AGREEMENT_FLOW";
export const FINALIZE_COMPANY_AGREEMENT_FLOW =
    "FINALIZE_COMPANY_AGREEMENT_FLOW";
export const VISIBILITY_RESIDENCE_PERMIT = "VISIBILITY_RESIDENCE_PERMIT";
export const VISIBILITY_RESIDENCE_PERMIT_VALID_UNTIL =
    "VISIBILITY_RESIDENCE_PERMIT_VALID_UNTIL";
export const VISIBILITY_STEP_5 = "VISIBILITY_STEP_5";
export const STEP_VISIBILITY = "STEP_VISIBILITY";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const INIT_FORM_STATE = "INIT_FORM_STATE";
export const TOGGLE_ACCORDION = "TOGGLE_ACCORDION";
export const TOGGLE_HIGHLIGHT = "TOGGLE_HIGHLIGHT";
export const SHOW_END_OF_CONTRACT = "SHOW_END_OF_CONTRACT";
export const OPEN_STATIC = "OPEN_STATIC";
export const CLOSE_STATIC = "CLOSE_STATIC";
export const HANDLE_AJAX_ERROR = "HANDLE_AJAX_ERROR";
export const INIT_FAQ_MODEL = "INIT_FAQ_MODEL";
export const INIT_ORDERSTATUS_MODEL = "INIT_ORDERSTATUS_MODEL";
export const INIT_REJECTION_MODEL = "INIT_REJECTION_MODEL";
export const IDENTITY_TYPE = "IDENTITY_TYPE:";
export const VISIBILITY_BIC_BLZ_FIELD = "VISIBILITY_BIC_BLZ_FIELD";
export const SET_VOUCHER_CODE = "SET_VOUCHER_CODE";
export const CONFIG_STEP_VISIBILITY = "CONFIG_STEP_VISIBILITY";
export const CONTRACT_PARTNER = "CONTRACT_PARTNER";
export const CHECKOUT_TYPE = "CHECKOUT_TYPE";
export const UPDATE_TARIFF_BASIC_CHARGE = "UPDATE_TARIFF_BASIC_CHARGE";
export const UPDATE_TARIFF_SUMMERY = "UPDATE_TARIFF_SUMMERY";
export const SELECTED_SIMCARDS = "SELECTED_SIMCARDS";
export const GET_SUPERSELECT_SIMCARD_PRICE = "GET_SUPERSELECT_SIMCARD_PRICE";
export const TOGGLE_FLAT_TYPE = "TOGGLE_FLAT_TYPE";
export const SET_SUBCARDS_COUNT = "SET_SUBCARDS_COUNT";
export const SUPERSELECT_SECOND_SIM_ALLNETFLAT = "SUPERSELECT_SECOND_SIM_ALLNETFLAT";
export const EECC_GENERATE_DOCUMENT = "EECC_GENERATE_DOCUMENT";
export const EECC_DOWNLOAD_DOCUMENT = "EECC_DOWNLOAD_DOCUMENT";
export const GET_PDF_FILES_FROM_PROVIDER = "GET_PDF_FILES_FROM_PROVIDER";
export const USER_HAS_GOT_THE_PDF_FILE = "USER_HAS_GOT_THE_PDF_FILE";
export const SEND_MAIL_TO_PROVIDER = "SEND_MAIL_TO_PROVIDER";
export const TARIFF_TYPE = "TARIFF_TYPE";
export const SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE";
export const SET_ADDRESS_VALIDATED = "SET_ADDRESS_VALIDATED";
export const SET_ACTIVE_ATTACHMENTS = 'SET_ACTIVE_ATTACHMENTS';
export const SET_PRICE_GROSS = 'SET_PRICE_GROSS';