import Axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

import {
  GET_CHECKOUT_ADDRESS_DATA,
  setCheckoutAddressData,
  setDslCheckoutData,
  GET_CHECKOUT_DELIVERY_ADDRESS_DATA,
  setCheckoutDeliveryAddressData
} from './actions';

const options = {
  timeout: 30000,
  addressDataUrl: '/helper/get-address'
};

export function* getAddressData(action) {
  const {
    payload
  } = action;

  try {
    if (payload && payload.length === 5) {
      const addressData = yield Axios({
        method: 'post',
        url: options.addressDataUrl,
        timeout: options.timeout,
        data: {
          zip: payload
        }
      });

      // first check if klicktel api is down
      if (addressData.data.statusCode === 200) {
        const citiesArr = addressData.data.cities
          ? Object.keys(addressData.data.cities)
          : [];

        if (citiesArr.length) {
          const streetsArr = addressData.data.streets
            ? Object.keys(addressData.data.streets)
            : [];
          yield put(
            setCheckoutAddressData({
              cities: citiesArr,
              streets: streetsArr
            })
          );

          if (citiesArr.length === 1) {
            yield put(
              setDslCheckoutData({
                town: citiesArr[0]
              })
            );
          }
          if (streetsArr.length === 1) {
            yield put(
              setDslCheckoutData({
                street: streetsArr[0]
              })
            );
          }
        } else {
          // empty citiesArr means the zip is invalid!
          // INVALID ZIP !!
          yield put(
            setCheckoutAddressData({
              cities: [],
              streets: []
            }),
          );
          yield put(
            setDslCheckoutData({
              town: '',
              street: '',
              streetNumber: ''
            })
          );
        }
      } else {
        // klicktel doesn't answer so reset the fields and set them to free input
        yield put(
          setDslCheckoutData({
            town: '',
            street: '',
            streetNumber: ''
          })
        );
      }
    }
  } catch (error) {
    console.log('checkoutsaga error', error);
  }
}

export function* getDeliveryAddressData(action) {
  const {
    payload
  } = action;
  try {
    if (payload && payload.length === 5) {
      const addressData = yield Axios({
        method: 'post',
        url: options.addressDataUrl,
        timeout: options.timeout,
        data: {
          zip: payload
        }
      });

      const citiesArr = addressData.data.cities
        ? Object.keys(addressData.data.cities)
        : [];
      const streetsArr = addressData.data.streets
        ? Object.keys(addressData.data.streets)
        : [];
      yield put(setCheckoutDeliveryAddressData({
        cities: citiesArr,
        streets: streetsArr
      }));

      if (citiesArr.length === 1) {
        yield put(setDslCheckoutData({
          deliveryTown: citiesArr[0]
        }));
      } else if (!citiesArr.length) {
        yield put(setDslCheckoutData({
          deliveryTown: '',
          deliveryStreet: '',
          deliveryStreetNumber: ''
        }));
      }

      if (streetsArr.length === 1) {
        yield put(setDslCheckoutData({
          deliveryStreet: streetsArr[0]
        }));
      }
    }
  } catch (error) {
    console.log('checkoutsaga error', error);
  }
}

export function* waitForCheckoutDeliveryAddressData() {
  yield takeLatest(GET_CHECKOUT_DELIVERY_ADDRESS_DATA, getDeliveryAddressData);
}

export function* waitForCheckoutAddressData() {
  yield takeLatest(GET_CHECKOUT_ADDRESS_DATA, getAddressData);
}

export const dslCheckoutAddressSaga = [
  waitForCheckoutAddressData(),
  waitForCheckoutDeliveryAddressData()
];
