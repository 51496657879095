import _ from 'lodash';
import updateArray from '@core/frontend/src/global/helper/updateArray';

export const initialState = {
  landingPageProductGridData: [],
  landingPageData: [],
  activeFilter: {
    accessories: null,
    accessorySelectedVariants: null,
    accessoryVariants: null,
    offer_id: null,
    variant: null,
    selectedVariant: null,
    hoverEl: null,
    carrier: null,
    manufacturer: null,
    selectedTariff: null,
    selectedProduct: null,
    hoverProduct: null,
    tariff: null,
    detailsTariff: null,
    detailsHardware: null,
    activeInfoTariff: null,
    selectedOffer: null,
    offergroup: null,
    hoverOffer: null,
    activeAddition: null
  },
  tariffModalOpen: false,
  deliveryModalOpen: false,
  tariffModalContent: null,
  detailsVisibility: false,
  familyfilter: null,
  appliedFilters: [],
  nfilterCandidates: [],
  nfilterCandidates2Remove: [],
  filterMenuOpen: false,
  filterMenuActiveCategory: null,
  mobilefilter_containergroup: 'manufacturer',
  filtertype: 'offers',
  filtercategories: [],
  offers: [],
  addressData: {
    cities: [],
    streets: []
  },
  energyForm: {},
  energyFormErrors: {},
  energyLoader: false,
  energyResult: undefined,
  energyPlaces: [],
  dslForm: {},
  dslFormErrors: {},
  dslLoader: false,
  dslResult: {},
  dslSpeed: 0,
  stocks: {}
};

const accessoryVariants = (state, action) => state.activeFilter.accessoryVariants.map((variant, index) => {
  if (index === action.data.index) {
    return action.data.value;
  }
  return variant;
});

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'INIT_PRODUCTDETAILS_MODEL':
      return { ...default_state, ...state };
    case 'ADD_PRODUCT':
      return {
        ...state,
        [action.data.offer_id]: { ..._.omit(action.data, 'id') }
      };
    case 'SET_OFFERID':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          offer_id: action.data
        }
      };
    case 'SET_PRODUCT_SELECTED':
      return {
        ...state,
        selectedProduct: action.data
      };
    case 'SET_PRODUCT_COLOR':
      return {
        ...state,
        selectedProductColor: action.data
      };
    case 'SET_ACCESSORY_COLOR':
      return {
        ...state,
        selectedAccessoryColor: action.data
      };
    case 'SET_PRODUCT_HOVER_COLOR':
      return {
        ...state,
        selectedProductHoverColor: action.data
      };
    case 'SET_HARDWARE_VARIANT':
      return {
        ...state,
        [action.data.productId]: {
          ...state[action.data.productId],
          selectedHardwareVariant: action.data.variant
        },
        activeFilter: {
          ...state.activeFilter,
          selectedVariant: action.data.variant,
          variant: action.data.variant
        }
      };
    case 'UPDATE_STOCKS_LP':
      return {
        ...state,
        stocks: { ...state.stocks, ...action.data }
      };
    case 'SET_HOVER_HARDWARE_VARIANT':
      return {
        ...state,
        [action.data.productId]: {
          ...state[action.data.productId],
          selectedHardwareHoverVariant: action.data.variant
        }
      };
    case 'SET_ACCESSORY_VARIANT_NEW':
      return {
        ...state,
        [action.data.productId]: {
          ...state[action.data.productId],
          selectedAccessoryVariant: {
            ...state[action.data.productId].selectedAccessoryVariant,
            [action.data.accessoryId]: action.data.variant
          }
        }
      };
    case 'SET_ACCESSORY_HOVER_VARIANT':
      return {
        ...state,
        [action.data.productId]: {
          ...state[action.data.productId],
          selectedAccessoryHoverVariant: {
            ...state[action.data.productId].selectedAccessoryHoverVariant,
            [action.data.accessoryId]: action.data.variant
          }
        }
      };
    case 'SET_ACCESSORY_HOVER_COLOR':
      return {
        ...state,
        selectedAccessoryHoverColor: action.data
      };
    case 'SET_PAGEOFFER':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          offergroup: action.data.offergroup ? action.data.offergroup : null,
          selectedOffer: action.data.offer,
          hoverOffer: action.data.offer,
          selectedProduct: action.data.hardware,
          selectedHardware: action.data.hardware,
          hoverProduct: action.data.hardware,
          selectedVariant: action.data.variant,
          variant: action.data.variant,
          accessories: action.data.accessories,
          accessoryVariants: action.data.accessoryVariants,
          accessorySelectedVariants: action.data.accessoryVariants,
          selectedTariff: action.data.tariff,
          tariff: action.data.tariff,
          activeAddition: action.data.activeAddition
            ? action.data.activeAddition
            : null,
          offer_id: action.data.offer.offer_id,
          carrier: null
        }
      };
    case 'SET_HOVER_PAGEOFFER':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          hoverOffer: action.data.offer,
          hoverProduct: action.data.hardware,
          variant: action.data.variant,
          accessories: action.data.accessories,
          accessoryVariants: action.data.accessoryVariants,
          tariff: action.data.tariff,
          activeAddition: action.data.activeAddition
            ? action.data.activeAddition
            : null,
          offer_id: action.data.offer.offer_id,
          carrier: null
        }
      };
    case 'SET_VARIANT':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          selectedVariant: action.data,
          variant: action.data
        }
      };
    case 'SET_PRODUCT':
      return {
        ...state,
        selectedProduct: {
          ...state.selectedProduct,
          ...action.data
        },
        selectedHardware: {
          ...state.selectedProduct,
          ...action.data
        }
      };
    case 'SET_IN_PATH_VALUE':
      return {
        ..._.set(Object.assign({}, state), action.data.path, action.data.value)
      };
    case 'SET_OFFER':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          selectedOffer: action.data
        }
      };
    case 'SHOW_VARIANT':
      let filteredTariff;
      if (!action.data.variant) {
        return {
          ...state,
          activeFilter: {
            ...state.activeFilter,
            variant: state.activeFilter.selectedVariant,
            hoverEl: null
          }
        };
      }
      if (state.type === 'hardware') {
        const tariffMatch = Object.values(action.data.variant.tariffs).filter(
          tariff => tariff.ebootisId
            === state.activeFilter.selectedTariff.ebootisId
        );

        if (tariffMatch.length > 0) {
          filteredTariff = tariffMatch[0];
        } else {
          const filteredArray = action.data.variant.tariffs.filter(
            tariff => tariff.default === true
          );

          filteredTariff = filteredArray[0];
        }
      } else {
        filteredTariff = state.activeFilter.tariff;
      }

      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          variant: action.data.variant,
          hoverEl: action.data.hoverEl,
          tariff: filteredTariff
        }
      };
    case 'SET_CARRIER':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          carrier: action.data
        }
      };
    case 'SET_MANUFACTURER':
      if (action.data === '') {
        action.data = null;
      }
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          manufacturer: action.data
        }
      };
    case 'SET_SELECTED_TARIFF':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          selectedTariff: action.data,
          tariff: action.data
        }
      };
    case 'SET_SELECTED_PRODUCT':
      let activeVariant;
      action.data.hardware.variants.forEach((variant) => {
        if (!!action.data.variant && variant.ebootisId
          === action.data.variant) {
          activeVariant = variant;
          return true;
        }
        if (!action.data.variant && variant.default === true) {
          activeVariant = variant;
          return true;
        }
      });
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          selectedProduct: action.data.hardware,
          selectedHardware: action.data.hardware, // TODO: refactor
          selectedVariant: activeVariant,
          variant: activeVariant
        }
      };
    case 'SHOW_TARIFF':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          tariff: action.data
        }
      };
    case 'SET_TARIFF_DETAILS':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          detailsTariff: action.data
        }
      };
    case 'SET_HARDWARE_DETAILS':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          detailsHardware: action.data
        }
      };
    case 'SET_GENERAL_HARDWARE_DETAILS':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          generalHardwareDetails: action.data
        }
      };
    case 'SET_ACTIVE_INFO_TILE':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          activeInfoTariff: action.data
        }
      };
    case 'OPEN_TARIFF_MODAL':
      return {
        ...state,
        tariffModalOpen: action.data
      };
    case 'CLOSE_TARIFF_MODAL':
      return {
        ...state,
        tariffModalOpen: action.data
      };
    case 'OPEN_DELIVERY_MODAL':
      return {
        ...state,
        deliveryModalOpen: action.data
      };
    case 'CLOSE_DELIVERY_MODAL':
      return {
        ...state,
        deliveryModalOpen: action.data
      };
    case 'GET_MODAL_CONTENT':
      return {
        ...state,
        tariffModalContent: action.payload
      };
    case 'SWITCH_ADDITION':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          activeAddition: action.data
        }
      };
    case 'SET_DETAILS_VISIBILITY':
      return {
        ...state,
        detailsVisibility: true
      };
    case 'SET_HARDWARE_DETAILLIST':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          selectedProduct: {
            ...state.activeFilter.selectedProduct,
            details: action.data
          }
        }
      };
    case 'SET_TARIFF_DETAILLIST':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          selectedTariff: {
            ...state.activeFilter.selectedTariff,
            detaillist: action.data
          }
        }
      };
    case 'SET_FAMILYFILTER':
      return {
        ...state,
        familyfilter: action.data
      };
    case 'SET_ACCESSORY':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          accessory: action.data
        }
      };
    case 'SET_ACCESSORY_VARIANT':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          accessoryVariants: accessoryVariants(state, action)
        }
      };
    case 'SET_ACCESSORY_SELECTED_VARIANT':
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          accessoryVariants: accessoryVariants(state, action),
          accessorySelectedVariants: accessoryVariants(state, action)
        }
      };
    case 'TOGGLE_FILTER_MENU':
      window.scrollTo(0, 0);

      if (!state.filterMenuOpen) {
        window.document.body.className += ' mobile-filter-open';
      } else {
        window.document.body.className = document.body.className.replace(
          ' mobile-filter-open', ''
        );
      }
      return {
        ...state,
        filterMenuOpen: !state.filterMenuOpen,
        filterMenuActiveCategory: state.filterMenuOpen
          ? null
          : state.filterMenuActiveCategory
      };

    case 'CHANGE_MOBILE_FILTER_CATEGORY':
      const mobile = document.getElementsByClassName(
        'mobile filter-wrapper'
      ).length;

      let { category } = action.data;

      if (typeof category === 'undefined') {
        if (mobile) {
          category = state.mobilefilter_containergroup;
        } else {
          category = null;
        }
      }
      return {
        ...state,
        filterMenuActiveCategory: category,
        nfilterCandidates: [],
        nfilterCandidates2Remove: []
      };

    case 'ADD_FILTER':
      return {
        ...state,
        appliedFilters: [
          ...state.appliedFilters,
          {
            group: action.data.group,
            value: action.data.value
          }
        ]
      };

    case 'REMOVE_FILTER':
      return {
        ...state,
        appliedFilters: state.appliedFilters.map((filter) => {
          if (
            filter.group !== action.data.group
            || filter.value.value !== action.data.value.value
          ) {
            return filter;
          }
        }).filter(value => typeof value !== 'undefined')
      };

    case 'DELETE_FILTERS':
      if (state.filterMenuOpen) {
        window.document.body.className = document.body.className.replace(
          ' mobile-filter-open', ''
        );
      }

      return {
        ...state,
        appliedFilters: [],
        nfilterCandidates: [],
        nfilterCandidates2Remove: [],
        filterMenuOpen: false,
        filterMenuActiveCategory: null
      };

    case 'UPDATE_FILTER':
      return {
        ...state,
        appliedFilters: state.appliedFilters.map((filter, index) => {
          if (filter.group === action.data.group) {
            return {
              group: action.data.group,
              value: action.data.value
            };
          }
          return filter;
        }).filter(value => typeof value !== 'undefined')
      };

    case 'SET_HISTORY_STATE':
      return {
        ...state,
        appliedFilters: action.data.appliedFilters
      };

    case 'UPDATE_STOCKS':
      // products .. naming not ideal -- in parent is offerGroups... should be an array of objects every times - avoid this ifs
      let products = state.product ? state.product : state.products;
      if (state.landingPageProductGridData
        && state.landingPageProductGridData.length > 0) {
        products = state.landingPageProductGridData;
      }
      const updatedProducts = updateArray(products, action.data);
      const returnObject = { ...state };

      // fill all variants... :-( reduce if's
      return {
        ...state,
        product: updatedProducts,
        products: updatedProducts,
        landingPageProductGridData: updatedProducts
      };

    case 'ADD_FILTER_CANDIDATE':
      return {
        ...state,
        nfilterCandidates: [
          ...state.nfilterCandidates,
          {
            group: action.data.group,
            value: action.data.value
          }
        ]
      };

    case 'ADD_FILTERREMOVE_CANDIDATE':
      return {
        ...state,
        nfilterCandidates2Remove: [
          ...state.nfilterCandidates2Remove,
          {
            group: action.data.group,
            value: action.data.value
          }
        ]
      };

    case 'REMOVE_FILTERCANDIDATE':
      return {
        ...state,
        nfilterCandidates: state.nfilterCandidates.map((candidate, index) => {
          if (
            candidate.group !== action.data.group
            || candidate.value.value !== action.data.value.value
          ) {
            return candidate;
          }
        }).filter(value => typeof value !== 'undefined')
      };

    case 'REMOVE_FILTERCANDIDATE2REMOVE':
      return {
        ...state,
        nfilterCandidates2Remove: state.nfilterCandidates2Remove.map(
          (candidate, index) => {
            if (candidate.group !== action.data.group || candidate.value
              !== action.data.value) {
              return candidate;
            }
          }
        ).filter(value => typeof value !== 'undefined')
      };

    case 'SET_LANDINGPAGE_DATA':
      return {
        ...state,
        landingPageData: action.data
      };
    case 'SET_LANDINGPAGE_PRODUCT_GRID_DATA':
      return {
        ...state,
        landingPageProductGridData: action.data.landingPageProductGridData
      };
    case 'SET_OFFERS':
      return {
        ...state,
        offers: action.data
      };
    case 'SET_DSL_OFFERS':
      return {
        ...state,
        offers: Array.isArray(action.data.offers)
          ? action.data.offers
          : Object.values(action.data.offers),
        alternativeOffersCount: action.data.alternativeOffersCount,
        alternativeProviders: action.data.alternativeProviders
      };
    case 'SET_ENERGY_CHECK':
      return {
        ...state,
        energyForm: Object.assign({}, state.energyForm, action.payload)
      };
    case 'SET_ENERGY_CHECK_ERRORS':
      return {
        ...state,
        energyFormErrors: action.payload
      };
    case 'SET_ENERGY_LOADER':
      return {
        ...state,
        energyLoader: action.payload
      };
    case 'SET_ENERGY_RESULT':
      return {
        ...state,
        energyResult: action.payload
      };
    case "SET_PLACES_RESULT":
      return {
          ...state,
          energyPlaces: action.payload,
      };
    case 'SET_ENERGY_VOUCHER':
      return {
        ...state,
        energyForm:{
          energyVoucher: action.payload,
          energyType: "1"
        }
      };
    case 'SET_DSL_CHECK':
      return {
        ...state,
        dslForm: Object.assign({}, state.dslForm, action.payload)
      };
    case 'SET_DSL_ADDRESS_DATA':
      return {
        ...state,
        addressData: action.data
      };
    case 'SET_DSL_CHECK_ERRORS':
      return {
        ...state,
        dslFormErrors: action.payload
      };
    case 'SET_DSL_LOADER':
      return {
        ...state,
        dslLoader: action.payload
      };
    case 'SET_DSL_RESULT':
      return {
        ...state,
        dslResult: action.payload
      };
    case 'SET_DSL_IS_SUBMITTING':
      return {
        ...state,
        dslForm: { ...state.dslForm, isSubmitting: action.payload }
      };
    case 'SET_ACTIVE_OFFER':
      return {
        ...state,
        activeOfferMobile: {
          ...state.activeOfferMobile,
          [action.payload.carrier]: action.payload.offerId
        }
      };
    case 'SET_DSL_SPEED':
      return {
        ...state,
        dslSpeed: action.payload.speed
      };

    case 'RESET_DSL_FORM_DATA':
      return {
        ...state,
        dslForm: {},
        dslResult: ''
      };

    default: {
      return state;
    }
  }
};

export default reducer;
