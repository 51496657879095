export const getCookie = (cname) => {
    const name = `${cname}=`;

    const ca = document.cookie.split(";");

    let i;

    let c;

    const ca_length = ca.length;
    for (i = 0; i < ca_length; i += 1) {
        c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) !== -1) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const setCookie = (variable, value) => {
    let d = new Date();
    const expires_seconds = 60 * 60 * 24 * 7;
    d = new Date(d.getTime() + 1000 * expires_seconds);
    document.cookie = `${variable}=${value}; expires=${d.toGMTString()};`;
};
