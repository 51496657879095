export const CONNECTION_STEP = 'connection';
export const HARDWARE_STEP = 'hardware';
export const ADDRESS_STEP = 'address';
export const PAYMENT_STEP = 'payment';
export const TARIFF_STEP = 'tariff';
export const EECC_STEP = 'eecc';

export const SALUTATION_FEMALE = 'female';
export const SALUTATION_MALE = 'male';

export const PAYMENT_TYPE_INVOICE = 'invoice';
export const PAYMENT_TYPE_PREPAYMENT = 'prepayment';

export const CALL_BACK_TIME_MORNING = 'Morgens zwischen 09:00 Uhr und 12:00 Uhr';
export const CALL_BACK_TIME_NOON = 'Mittags zwischen 12:00 Uhr und 15:00 Uhr';
export const CALL_BACK_TIME_AFTERNOON = 'Nachmittags zwischen 15:00 Uhr und 17:00 Uhr';
export const CALL_BACK_TIME_EVENING = 'Abends zwischen 17:00 Uhr und 20:00 Uhr';

export const PROVIDER_VODAFONE = 'vodafone';
export const PROVIDER_VODAFONE_CABLE = 'kabel deutschland';
export const PROVIDER_VODAFONE_DSL = 'vodafone dsl';
export const PROVIDER_O2 = 'o2';
export const PROVIDER_O2_CABLE = 'o2 cable';
export const PROVIDER_UNITY = 'unitymedia';
export const CARRIER_NOT_FOUND = 'CARRIER_NOT_FOUND';
export const OWN_ROUTER = 'ownRouter';

export const BUILDING_TYPE_DEFAULT = '* Gebäudeart';

export const BUILDING_TYPE_1 = 'Einfamilienhaus';
export const BUILDING_TYPE_2 = 'Mehrfamilienhaus (Einzelgebäude)';
export const BUILDING_TYPE_3 = 'Mehrfamilienhaus (Vorderhaus)';
export const BUILDING_TYPE_4 = 'Mehrfamilienhaus (Hinterhaus)';

export const FLOOR_TYPE_DEFAULT = 'Stockwerk/Etage';

export const FLOOR_TYPE_1 = 'Dachgeschoss';
export const FLOOR_TYPE_2 = 'Obergeschoss';
export const FLOOR_TYPE_3 = 'Erdgeschoss';
export const FLOOR_TYPE_4 = 'Keller';

export const ALIGNMENT_TYPE_DEFAULT = 'Ausrichtung';

export const ALIGNMENT_TYPE_LEFT = 'Links';
export const ALIGNMENT_TYPE_RIGHT = 'Rechts';
export const ALIGNMENT_TYPE_MID_LEFT = 'Mitte Links';
export const ALIGNMENT_TYPE_MID_RIGHT = 'Mitte Rechts';

export const ACTIVATION_WINDOW_NOT_SPECIFIED = 'NOT_SPECIFIED';
export const ACTIVATION_WINDOW_EIGHT_TO_TWELVE = 'EIGHT_TO_TWELVE';
export const ACTIVATION_WINDOW_TWELVE_TO_FOUR = 'TWELVE_TO_FOUR';

export const ACTIVATION_WINDOW_NOT_SPECIFIED_LABEL = 'Nein';
export const ACTIVATION_WINDOW_EIGHT_TO_TWELVE_LABEL = '8 - 12 Uhr';
export const ACTIVATION_WINDOW_TWELVE_TO_FOUR_LABEL = '12 - 16 Uhr';

export const EVN_DECLINE = 'evnDecline';
export const EVN_ACCEPT_SHORT = 'evnAcceptShort';
export const EVN_ACCEPT = 'evnAccept';

export const EVN_DECLINE_LABEL = 'Nein, ich wünsche keinen Einzelverbindungsnachweis.';
export const EVN_ACCEPT_SHORT_LABEL = 'Ja, ich wünsche einen um die letzten drei Rufnummern gekürzten Einzelverbindungsnachweis.';
export const EVN_ACCEPT_LABEL = 'Ja, ich wünsche einen vollständigen Einzelverbindungsnachweis.';
