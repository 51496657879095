export const STROM_COPY_TEXT = 'Jetzt verfügbare Strompreise checken:';
export const GAS_COPY_TEXT = 'Ermitteln Sie jetzt, welche günstigen Gastarife bei Ihnen verfügbar sind:';
export const ENERGY_TYPE_STROM = '1';
export const ENERGY_TYPE_GAS = '2';
export const ENERGY_ZIP_ERROR_EMPTY = 'Bitte geben Sie Ihre Postleitzahl ein.';
export const ENERGY_ZIP_ERROR_WRONG_FORMAT = 'Ihre Postleitzahl muss aus 5 Ziffern bestehen.';
export const ENERGY_CONSUMPTION_ERROR_EMPTY = 'Bitte geben Sie Ihren Verbrauch ein.';
export const ENERGY_CONSUMPTION_ERROR_WRONG_FORMAT = 'Bitte geben Sie einen gültigen Verbrauch ein.';
export const ENERGY_VOUCHER_ERROR_EMPTY = 'Bitte geben Sie Ihren Promocode ein.';
export const ENERGY_VOUCHER_ERROR_WRONG_FORMAT = 'Bitte geben Sie einen gültigen Promocode ein.';
export const ENERGY_RESULT_SUCCESS = 'ENERGY_RESULT_SUCCESS';
export const ENERGY_RESULT_ERROR = 'ENERGY_RESULT_ERROR';
