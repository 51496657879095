import "core-js/stable";
import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import dynostore, { dynamicReducers } from "@redux-dynostore/core";
import { dynamicSagas } from "@redux-dynostore/redux-saga";
import {
    createResponsiveStateReducer,
    responsiveStoreEnhancer,
} from "redux-responsive";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import _ from "lodash";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { QueryClient, QueryClientProvider } from "react-query";

import "regenerator-runtime/runtime";

import PageHeader from "@mm/shop/src/components/organisms/PageHeader";
import PageFooter from "@mm/shop/src/components/organisms/PageFooter";
import { ThemeProvider } from "@core/theme";
import { IntlProvider, messages } from "@core/text";
import { addResponsiveHandlers } from "@core/frontend/src/support/responsive-utils";

/**
 * Reducers and States
 * * */
import rejectionReducer, {
    initialState as rejectionInitialState,
} from "@core/frontend/src/rejection/reducer";
import pageReducer, {
    initialState as pageInitialState,
} from "@core/frontend/src/reducers/page";

import rootSaga from "./rootSaga";

import headerReducer, {
    initialState as headerInitialState,
} from "./global/header/reducer";
import footerReducer, {
    initialState as footerInitialState,
} from "./global/footer/reducer";
import landingpagesReducer, {
    initialState as landingpagesInitialState,
} from "./landingpages/reducer";
import tariffoverviewReducer, {
    initialState as tariffoverviewInitialState,
} from "./tariffoverview/reducer";
import overviewReducer, {
    initialState as overviewInitialState,
} from "./overview/reducer";
import faqReducer, { initialState as faqInitialState } from "./faq/reducer";
import campaignReducer, {
    initialState as campaignInitialState,
} from "./campaign/reducer";
import revocationReducer, {
    initialState as revocationInitialState,
} from "./revocation/reducer";
import checkoutDefaultReducer, {
    initialState as checkoutDefaultInitialState,
} from "./checkout/reducer";

import checkoutNewReducer from "./modules/Checkout/reducer";
import { dslInitialState as checkoutDslInitialState } from "./modules/Checkout/DSLCheckout/initialState";
import { energyInitialState as checkoutEnergyInitialState } from "./modules/Checkout/EnergyCheckout/initialState";

import orderconfirmationReducer, {
    initialState as orderconfirmationInitialState,
} from "./orderconfirmation/reducer";

import Breadcrumb from "./global/breadcrumb/Breadcrumb";

import Pages from "./reactEntries";

import "./depricatedApp.scss";

const queryClient = new QueryClient();

const storeBreakPoints = {
    xs: 511,
    sm: 751,
    md: 1007,
    lg: 1231,
    xl: 10000,
};

const PAGE = {
    landingpages: Pages.Landingpages,
    pagenotfound: Pages.PageNotFound,
    rejection: Pages.Rejection,
    tariffoverview: Pages.TariffOverview,
    overview: Pages.Overview,
    faq: Pages.FAQ,
    campaign: Pages.Campaign,
    orderconfirmation: Pages.Orderconfirmation,
    revocation: Pages.Revocation,
    checkoutDefault: Pages.CheckoutDefault,
    checkoutDsl: Pages.CheckoutDsl,
    checkoutEnergy: Pages.CheckoutEnergy,
    checkoutO2affiliate: Pages.CheckoutO2Affiliate,
}[window.PAGE_TYPE];

const payPalOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "EUR",
    intent: "capture",
};

const initialStates = {
    landingpages: landingpagesInitialState,
    rejection: rejectionInitialState,
    tariffoverview: tariffoverviewInitialState,
    overview: overviewInitialState,
    faq: faqInitialState,
    campaign: campaignInitialState,
    orderconfirmation: orderconfirmationInitialState,
    revocation: revocationInitialState,
    checkoutDefault: checkoutDefaultInitialState,
    checkoutDsl: checkoutDslInitialState,
    checkoutEnergy: checkoutEnergyInitialState,
    checkoutO2affiliate: checkoutDefaultInitialState,
};

const reducers = {
    landingpages: landingpagesReducer,
    rejection: rejectionReducer,
    tariffoverview: tariffoverviewReducer,
    overview: overviewReducer,
    faq: faqReducer,
    campaign: campaignReducer,
    orderconfirmation: orderconfirmationReducer,
    revocation: revocationReducer,
    checkoutDefault: checkoutDefaultReducer,
    checkoutDsl: checkoutNewReducer,
    checkoutEnergy: checkoutNewReducer,
    checkoutO2affiliate: checkoutDefaultReducer,
};

const getComponentsInitialState = (name, props) => {
    switch (name) {
        case "checkoutDefault":
        case "checkoutO2affiliate":
            return {
                model: {
                    ...initialStates[name].model,
                    ...props,
                },
            };
        case "checkoutSky":
        case "checkoutEnergy":
            return {
                ...initialStates[name],
                ...props.initialData,
                model: props,
            };
        case "checkoutDsl":
            const preloaded = _.merge(
                checkoutDslInitialState,
                props.initialData
            );
            return {
                ...initialStates[name],
                ...preloaded,
                model: props,
            };
        default:
            return {
                ...initialStates[name],
                ...props,
            };
    }
};

const getInitialState = (name, reducerName, props) => {
    const extendedPageInitialState = {
        ...pageInitialState,
        deliverySettings: props.deliverySettings,
        hotlines: props.hotlines,
    };

    const extendedHeaderInitialState = {
        ...headerInitialState,
        navigation: props.navigation,
    };

    const remainingProps = _.omit(props, [
        "breadcrumb",
        "hotlines",
        "fm",
        "navigation",
        "notes",
    ]);
    const componentInitialState = getComponentsInitialState(
        name,
        remainingProps
    );
    return {
        page: extendedPageInitialState,
        header: extendedHeaderInitialState,
        [reducerName]: componentInitialState,
        footer: footerInitialState,
    };
};

const getCombinedReducer = (name, reducerName) =>
    combineReducers({
        page: pageReducer,
        header: headerReducer,
        [reducerName]: reducers[name],
        footer: footerReducer,
        viewport: createResponsiveStateReducer(storeBreakPoints),
    });

const getStore = (name, reducerName, props) => {
    const combinedReducers = getCombinedReducer(name, reducerName);
    const initialState = getInitialState(name, reducerName, props);
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        combinedReducers,
        initialState,
        process.env.NODE_ENV === "development"
            ? composeWithDevTools(
                applyMiddleware(sagaMiddleware, thunk),
                responsiveStoreEnhancer,
                dynostore(dynamicReducers(), dynamicSagas(sagaMiddleware))
            )
            : compose(
                  applyMiddleware(sagaMiddleware, thunk),
                  responsiveStoreEnhancer,
                dynostore(dynamicReducers(), dynamicSagas(sagaMiddleware))
            )
    );
    addResponsiveHandlers(store);
    sagaMiddleware.run(rootSaga); // doesn't run on faq|rejection|overview|campaign|revocation page
    return store;
};

ReactDOM.render(
    <Provider
        store={getStore(
            window.PAGE_TYPE,
            window.PAGE_TYPE.includes("checkout")
                ? "checkout"
                : window.PAGE_TYPE,
            window.PAGE_PROPS
        )}
    >
        <ThemeProvider>
            <IntlProvider locale="de" messages={messages}>
                <QueryClientProvider client={queryClient}>
                    <PayPalScriptProvider options={payPalOptions}>
                        <Suspense fallback={null}>
                            <PageHeader itemCount={1} />
                            {!_.isEmpty(window.PAGE_PROPS.breadcrumb) && (
                                <Breadcrumb items={window.PAGE_PROPS.breadcrumb} />
                            )}
                            {React.createElement(PAGE)}
                            <PageFooter />
                        </Suspense>
                    </PayPalScriptProvider>
                </QueryClientProvider>
            </IntlProvider>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root")
);
