import Axios from 'axios';
import {
  put, takeEvery, call, select
} from 'redux-saga/effects';
import { get } from 'lodash';
import scrollTo from '../../global/helper/scrollSmoothlyToId';
import { CHECKOUT_LOGIC, CHECKOUT_ERRORS } from './constants';
import {
  VALIDATE_CHECKOUT_STEP,
  setCheckoutErrors,
  setValidCheckoutStep,
  setActiveCheckoutStep,
  setEditModeCheckout
} from './actions';
import { getLastInvalidStep } from './selectors';

// Workers
export function* validateCheckoutStep(action) {
  const {
    checkoutType,
    checkoutData,
    activeStepName,
    stepNumber,
    nextStepName,
    nextStepNumber,
    isEditMode,
    isLastStep
  } = action;

  try {
    yield Axios.post(`/checkout/validate/${stepNumber}/`, checkoutData);
    window.history.pushState(null, null, `/checkout/step/${nextStepNumber}/`);
    yield put(
      setValidCheckoutStep(checkoutType + CHECKOUT_LOGIC, activeStepName,
        true)
    );
    const lastInvalidStep = yield select(getLastInvalidStep,
      checkoutType + CHECKOUT_LOGIC);
    const lastInvalidStepName = get(lastInvalidStep, 'type',
      'checkout-summary');
    yield put(setEditModeCheckout(checkoutType + CHECKOUT_LOGIC, isEditMode));

    if (!isLastStep) {
      yield put(
        setValidCheckoutStep(checkoutType + CHECKOUT_LOGIC, lastInvalidStepName,
          false)
      );
    }

    yield put(setActiveCheckoutStep(checkoutType + CHECKOUT_LOGIC,
      lastInvalidStepName));
    yield call(scrollTo, lastInvalidStepName, true);
  } catch (error) {
    if (error.response.status === 400) {
      yield put(
        setCheckoutErrors(checkoutType + CHECKOUT_ERRORS, error.response.data)
      );
      yield put(
        setValidCheckoutStep(checkoutType + CHECKOUT_LOGIC, activeStepName,
          false)
      );
    }
  }
}

// Watchers
export function* waitForStepSubmit() {
  yield takeEvery(VALIDATE_CHECKOUT_STEP, validateCheckoutStep);
}

export const checkoutSaga = [
  waitForStepSubmit()
];
