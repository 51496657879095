import Axios from 'axios';
import { put, takeEvery } from 'redux-saga/effects';

import {
  setRichSeoDataForOverview
} from '@core/frontend/src/actions/overview';

const options = {
  timeout: 10000,
  url: '/helper/get-rich-seo/'
};

// Workers
export function* getRichSeoDataForOverview(action) {
  const {
    data
  } = action;

  try {
    const richSeoData = yield Axios({
      method: 'post',
      url: options.url,
      timeout: options.timeout,
      data
    });

    yield put(setRichSeoDataForOverview(richSeoData.data));
  } catch (error) {
    // console.log('error@getRichSeoData', error)
  }
}

// Watchers
export function* waitForGetRichSeoDataOverview() {
  yield takeEvery('GET_RICH_SEO_DATA_FOR_OVERVIEW', getRichSeoDataForOverview);
}

export const getRichSeoSagaOverview = [
  waitForGetRichSeoDataOverview()
];
