import Axios from 'axios';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    SUBMIT_DSL_CHECK,
    GET_DSL_ADDRESS_DATA,
    GET_DSL_FORM_DATA,
    SUBMIT_DSL_FORM_DATA_RESET,
    GET_DSL_VODAFONE_VOUCHER_VALIDITY,
    resetDslFormData,
    setDslAddressData,
    setDslCheckErrors,
    setDslLoader,
    setDslResult,
    setDslCheck,
    setIsSubmitting,
    getDslFormData as getDslFormDataAction
} from './actions';

import {
    DSL_RESULT_SUCCESS,
    DSL_RESULT_ERROR,
    DSL_RESULT_EMPTY,
    DSL_RESULT_PROVIDER_EMPTY,
    DSL_ZIP_INVALID,
    DSL_VODAFONE_VOUCHER_RESULT_ERROR,
    DSL_VODAFONE_VOUCHER_INVALID,
    DSL_VODAFONE_VOUCHER_EMPTY_ERROR
} from './constants';

import { setDslOffers } from '@core/frontend/src/actions/landingpages';

const options = {
  timeout: 30000,
  offerDataUrl: `${window.REST_URL}/dsl/data?environment=${window.ENVIRONMENT}&tenant=${window.TENANT?.toLowerCase()}`,
  addressDataUrl: '/helper/get-address/',
  voucherValidityDataUrl: '/helper/validate-vodafone-voucher/',
  formDataUrl: '/helper/get-dsl-lp-data/',
  resetDslForm: '/helper/forget-dsl-address/'
};

// Workers
export function* submitDslCheck(action) {
  const { payload } = action;

  try {
    yield put(setIsSubmitting(true));
    const dslTypeUrl = window.location.pathname.split('/')[1];

    Object.assign(payload, {
      url: dslTypeUrl
    });

    const offerData = yield Axios({
      method: 'post',
      url: options.offerDataUrl,
      timeout: options.timeout,
      data: payload
    });

    if (offerData.status === 200) {
      yield put(setDslOffers(offerData.data));
      yield put(setIsSubmitting(false));
      const offers = Array.isArray(offerData.data.offers)
        ? offerData.data.offers
        : Object.values(offerData.data.offers);
      if (offers.length === 0) {
        if (offerData.data.alternativeOffersCount > 0) {
          yield put(setDslResult(DSL_RESULT_PROVIDER_EMPTY));
        } else {
            if (payload.vodafoneVoucher) {
                // if we have no results and we are on the vodafoneVoucher landingpage, show custom error in modal
                yield put(setDslResult(DSL_VODAFONE_VOUCHER_EMPTY_ERROR));

            } else {
                yield put(setDslResult(DSL_RESULT_EMPTY));
            }
        }
      } else {
        yield put(setDslResult(DSL_RESULT_SUCCESS));

      }

      window.dataLayer.push({
        event: offers.length > 0 ? 'calculationconfirm' : 'calculationerror',
        eventCategory: 'Form',
        eventAction: 'submit',
        eventLabel: offers.length > 0 ? 'calculationconfirm' : 'calculationerror',
        zip: payload.zip,
        city: payload.city,
        street: payload.street,
        streetnumber: payload.streetnumber
      });
    } else {
      window.dataLayer.push({ event: 'calcerror' });
      throw Error(DSL_RESULT_EMPTY);
    }
  } catch (error) {
    window.dataLayer.push({ event: 'calcerror' });
    yield put(setIsSubmitting(false));
    yield put(setDslOffers([]));
    yield put(setDslLoader(false));
    if (error.response && error.response.status === 400) {
      yield put(setDslCheckErrors(error.response.data));
    } else if (error.message && error.message === DSL_RESULT_EMPTY) {
      yield put(setDslResult(DSL_RESULT_EMPTY));
    } else {
      yield put(setDslResult(DSL_RESULT_ERROR));
    }
  }
}

export function* getAddressData(action) {
  const { payload } = action;
  try {
    yield put(setDslLoader(true));
    const addressData = yield Axios({
      method: 'post',
      url: options.addressDataUrl,
      timeout: options.timeout,
      data: {
        zip: payload
      }
    });

    // first check if klicktel api is down
    if (addressData.data.statusCode === 200) {
      const citiesArr = addressData.data.cities
        ? Object.keys(addressData.data.cities)
        : [];

      if (citiesArr.length) {
        const streetsArr = addressData.data.streets
          ? Object.keys(addressData.data.streets)
          : [];
        yield put(
          setDslAddressData({
            cities: citiesArr,
            streets: streetsArr
          })
        );
        if (citiesArr.length === 1) {
          yield put(
            setDslCheck({
              city: citiesArr[0]
            })
          );
        }
        if (streetsArr.length === 1) {
          yield put(
            setDslCheck({
              street: streetsArr[0]
            })
          );
        }
      } else {
        // empty citiesArr means the zip is invalid!
        yield put(
          setDslCheckErrors({
            zip: DSL_ZIP_INVALID
          })
        );
        yield put(
          setDslAddressData({
            cities: [],
            streets: []
          })
        );
      }
    } else {
      // klicktel doesn't answer so reset the fields and set them to free input
      yield put(
        setDslCheck({
          city: '',
          street: '',
          streetnumber: ''
        })
      );
      yield put(
        setDslAddressData({
          cities: [],
          streets: []
        })
      );
    }

    yield put(setDslLoader(false));
  } catch (error) {
    yield put(
      setDslAddressData({
        cities: [],
        streets: []
      })
    );
    yield put(
      setDslCheck({
        city: '',
        street: '',
        streetnumber: ''
      })
    );
    yield put(setDslResult(DSL_RESULT_ERROR));
    yield put(setDslLoader(false));
  }
}

export function* getVodafoneVoucherValidity(action) {
  const { payload } = action;
  try {
    yield put(setDslLoader(true));
    const voucherValidity = yield Axios({
      params: {
          vodafoneVoucher: payload
      },
      url: options.voucherValidityDataUrl,
      timeout: options.timeout,
    });

    // we only handle the "error" case. if the statusCode is 200, voucher is valid and everything is ok
    if (voucherValidity.status !== 200) {
        // there was either an error or the voucher is not valid.
        yield put(
            setDslCheck({
                city: '',
                street: '',
                streetnumber: ''
            })
        );
        yield put(
            setDslCheckErrors({
                vodafoneVoucher: DSL_VODAFONE_VOUCHER_INVALID
            })
        );
        yield put(setDslResult(DSL_VODAFONE_VOUCHER_RESULT_ERROR));
    }
    yield put(setDslLoader(false));
  } catch (error) {
      yield put(
          setDslCheck({
              city: '',
              street: '',
              streetnumber: ''
          })
      );
      yield put(
          setDslCheckErrors({
              vodafoneVoucher: DSL_VODAFONE_VOUCHER_INVALID
          })
      );
    yield put(setDslResult(DSL_VODAFONE_VOUCHER_RESULT_ERROR));
    yield put(setDslLoader(false));
  }
}

export function* getDslFormData(action) {
  const { url } = action;
  try {
    yield put(setIsSubmitting(true));
    const {
      data: {
        offers,
        alternativeOffersCount,
        alternativeProviders,
        dslForm: {
          zip, city, street, streetnumber
        },
        dslResult
      }
    } = yield Axios({
      method: 'post',
      url: options.formDataUrl,
      timeout: options.timeout,
      data: { url }
    });
    yield put(
      setDslOffers({ offers, alternativeOffersCount, alternativeProviders })
    );
    if (offers.length === 0 && !!zip) {
      if (alternativeOffersCount > 0) {
        yield put(setDslResult(DSL_RESULT_PROVIDER_EMPTY));
      } else {
        yield put(setDslResult(DSL_RESULT_EMPTY));
      }
    } else if (!zip && offers.length === 0) {
      yield put(setDslResult(''));
    } else {
      yield put(setDslResult(dslResult));
      document.getElementsByClassName('offer-list')[0].scrollIntoView(
        { behavior: 'smooth' }
      );
    }
    yield put(
      setDslCheck({
        zip,
        city,
        street,
        streetnumber
      })
    );
    yield put(setIsSubmitting(false));
  } catch (error) {
    console.log(error);
  }
}

export function* submitDslFormDataReset(action) {
  const { url } = action;
  try {
    const reset = yield Axios({
      method: 'get',
      url: options.resetDslForm,
      timeout: options.timeout
    });
    yield put(resetDslFormData());
  } catch (error) {
    console.log(error);
  }
  yield put(getDslFormDataAction(url));
}

// Watchers
export function* waitForDslCheck() {
  yield takeEvery(SUBMIT_DSL_CHECK, submitDslCheck);
}

export function* waitForAddressData() {
  yield takeLatest(GET_DSL_ADDRESS_DATA, getAddressData);
}

export function* waitForVodafoneVoucherValidity() {
  yield takeLatest(GET_DSL_VODAFONE_VOUCHER_VALIDITY, getVodafoneVoucherValidity);
}

export function* waitForDslFormData() {
  yield takeEvery(GET_DSL_FORM_DATA, getDslFormData);
}

export function* waitForDslFormReset() {
  yield takeEvery(SUBMIT_DSL_FORM_DATA_RESET, submitDslFormDataReset);
}

export const dslCheckSaga = [
  waitForDslCheck(),
  waitForAddressData(),
  waitForVodafoneVoucherValidity(),
  waitForDslFormData(),
  waitForDslFormReset()
];
