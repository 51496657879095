import { takeLatest } from 'redux-saga/effects';
import Axios from 'axios';
import { get } from 'lodash';
import { FETCH_DSL_BANK_NAME, FETCH_DSL_ADDRESS_DATA } from './actions';

import {
  getBankName
} from '@core/frontend/src/global/helper/checkout'

// Workers
export function* fetchDslBankName(action) {
  try {
    const response = yield getBankName(action.params)
    yield action.callback('bank', get(response, ['data', 'name'], ''));
    yield action.callback('bic', get(response, ['data', 'bic'], ''));
  } catch (error) {
    // TODO create fail action
    // console.log(error);
  }
}

export function* fetchDslAdressData(action) {
  try {
    const { zip } = action.params;
    const addressData = yield Axios.post('/helper/get-address/', { zip });

    // first check if klicktel api is down
    if (addressData.data.statusCode === 200) {
      const citiesArr = addressData.data.cities
        ? Object.keys(addressData.data.cities)
        : [];

      if (citiesArr.length) {
        const streetsArr = addressData.data.streets
          ? Object.keys(addressData.data.streets)
          : [];

        action.callback(citiesArr, streetsArr);
      } else {
        // empty citiesArr means the zip is invalid!
        action.callback([], []);
      }
    } else {
      // klicktel doesn't answer so reset the fields and set them to free input
      action.callback([], []);
    }
  } catch (error) {
    action.callback([], []);
  }
}

// Watchers
export function* waitForFetchBankName() {
  yield takeLatest(FETCH_DSL_BANK_NAME, fetchDslBankName);
}

export function* waitForFetchAdressData() {
  yield takeLatest(FETCH_DSL_ADDRESS_DATA, fetchDslAdressData);
}

export const dslCheckoutPaymentSaga = [
  waitForFetchBankName(),
  waitForFetchAdressData()
];
