import { omit } from 'lodash';
import updateArray from '@core/frontend/src/global/helper/updateArray';
import { getCookie, setCookie } from '@core/frontend/src/global/helper/cookies';

export const initialState = {
  offers: [],
  appliedFilters: [],
  nfilterCandidates: [],
  nfilterCandidates2Remove: [],
  filterMenuOpen: false,
  filterMenuActiveCategory: null,
  filtertype: 'hardwares',
  mobilefilter_containergroup: 'manufacturer',
  infoboxSmartphonesOpen: getCookie('infobox_smartphones') === '',
  infoboxTabletsOpen: getCookie('infobox_tablets') === ''
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'INIT_OVERVIEW_MODEL':
      return { ...DEFAULT_MODEL, ...state };
    case 'ADD_PRODUCT':
      return {
        ...state,
        [action.data.productId]: { ...omit(action.data.product, 'id') }

      };
    case 'TOGGLE_FILTER_MENU':
      window.scrollTo(0, 0);

      if (!state.filterMenuOpen) {
        window.document.body.className += ' mobile-filter-open';
      } else {
        window.document.body.className = document.body.className.replace(
          ' mobile-filter-open', ''
        );
      }

      return {
        ...state,
        filterMenuOpen: !state.filterMenuOpen,
        filterMenuActiveCategory: state.filterMenuOpen
          ? null
          : state.filterMenuActiveCategory
      };

    case 'CHANGE_MOBILE_FILTER_CATEGORY':
      const mobile = document.getElementsByClassName(
        'mobile filter-wrapper'
      ).length;

      let category = action.data.category;

      if (typeof (category) === 'undefined') {
        if (mobile) {
          category = state.mobilefilter_containergroup;
        } else {
          category = null;
        }
      }
      return {
        ...state,
        filterMenuActiveCategory: category,
        nfilterCandidates: [],
        nfilterCandidates2Remove: []
      };

    case 'ADD_FILTER':

      return {
        ...state,
        appliedFilters: [
          ...state.appliedFilters,
          {
            group: action.data.group,
            value: action.data.value
          }
        ]
      };

    case 'REMOVE_FILTER':
      return {
        ...state,
        appliedFilters: state.appliedFilters.map((filter) => {
          if (filter.group !== action.data.group || filter.value.value
            !== action.data.value.value) {
            return filter;
          }
        }).filter(value => typeof (value) !== 'undefined')
      };

    case 'DELETE_FILTERS':
      window.scrollTo(0, 0);
      if (state.filterMenuOpen) {
        window.document.body.className = document.body.className.replace(
          ' mobile-filter-open', ''
        );
      }

      return {
        ...state,
        appliedFilters: [],
        nfilterCandidates: [],
        nfilterCandidates2Remove: [],
        filterMenuOpen: false,
        filterMenuActiveCategory: null
      };

    case 'UPDATE_FILTER':
      return {
        ...state,
        appliedFilters: state.appliedFilters.map((filter, index) => {
          if (filter.group === action.data.group) {
            return {
              group: action.data.group,
              value: action.data.value
            };
          }
          return filter;
        }).filter(value => typeof (value) !== 'undefined')
      };

    case 'SET_HISTORY_STATE':
      return {
        ...state,
        appliedFilters: action.data.appliedFilters
      };

    case 'UPDATE_STOCKS':
      const products = state.product ? state.product : state.products;
      const updatedProducts = updateArray(products, action.data);
      const returnObject = { ...state };

      if (state.product) {
        returnObject.product = updatedProducts;
      } else {
        returnObject.products = updatedProducts;
      }

      return returnObject;

    case 'ADD_FILTER_CANDIDATE':
      return {
        ...state,
        nfilterCandidates: [
          ...state.nfilterCandidates,
          {
            group: action.data.group,
            value: action.data.value
          }
        ]
      };

    case 'ADD_FILTERREMOVE_CANDIDATE':
      return {
        ...state,
        nfilterCandidates2Remove: [
          ...state.nfilterCandidates2Remove,
          {
            group: action.data.group,
            value: action.data.value
          }
        ]
      };

    case 'REMOVE_FILTERCANDIDATE':
      return {
        ...state,
        nfilterCandidates: state.nfilterCandidates.map((candidate, index) => {
          if (candidate.group !== action.data.group || candidate.value.value
            !== action.data.value.value) {
            return candidate;
          }
        }).filter(value => typeof (value) !== 'undefined')
      };

    case 'REMOVE_FILTERCANDIDATE2REMOVE':
      return {
        ...state,
        nfilterCandidates2Remove: state.nfilterCandidates2Remove.map(
          (candidate, index) => {
            if (candidate.group !== action.data.group || candidate.value
              !== action.data.value) {
              return candidate;
            }
          }
        ).filter(value => typeof (value) !== 'undefined')
      };
    case 'SET_HARDWARE_VARIANT':
      return {
        ...state,
        [action.data.productId]: {
          ...state[action.data.productId],
          selectedHardwareVariant: action.data.variant
        }

      };
    case 'SET_HOVER_HARDWARE_VARIANT':
      return {
        ...state,
        [action.data.productId]: {
          ...state[action.data.productId],
          selectedHardwareHoverVariant: action.data.variant

        }
      };
    case 'REMOVE_SMARTPHONE_INFOBOX':
      setCookie('infobox_smartphones', 1);
      return {
        ...state,
        infoboxSmartphonesOpen: false
      };
    case 'REMOVE_TABLET_INFOBOX':
      setCookie('infobox_tablets', 1);
      return {
        ...state,
        infoboxTabletsOpen: false
      };
    case 'SET_RICH_SEO_DATA_FOR_OVERVIEW':
      return {
        ...state,
        richSEO: action.data
      };
    default:
      return state;
  }
};

export default reducer;
