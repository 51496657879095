export const SET_DSL_CHECK = 'SET_DSL_CHECK';
export const GET_DSL_ADDRESS_DATA = 'GET_DSL_ADDRESS_DATA';
export const GET_DSL_VODAFONE_VOUCHER_VALIDITY = 'GET_DSL_VODAFONE_VOUCHER_VALIDITY';
export const SET_DSL_ADDRESS_DATA = 'SET_DSL_ADDRESS_DATA';
export const GET_DSL_FORM_DATA = 'GET_DSL_FORM_DATA';
export const RESET_DSL_FORM_DATA = 'RESET_DSL_FORM_DATA';
export const SUBMIT_DSL_FORM_DATA_RESET = 'SUBMIT_DSL_FORM_DATA_RESET';
export const SUBMIT_DSL_CHECK = 'SUBMIT_DSL_CHECK';
export const SET_DSL_CHECK_ERRORS = 'SET_DSL_CHECK_ERRORS';
export const SET_DSL_LOADER = 'SET_DSL_LOADER';
export const SET_DSL_RESULT = 'SET_DSL_RESULT';
export const SET_DSL_IS_SUBMITTING = 'SET_DSL_IS_SUBMITTING';

export function setDslCheck(payload) {
  return {
    type: SET_DSL_CHECK,
    payload
  };
}

export function setIsSubmitting(payload) {
  return {
    type: SET_DSL_IS_SUBMITTING,
    payload
  };
}

export const setDslAddressData = (data) => ({
  type: SET_DSL_ADDRESS_DATA,
  data
});

export function submitDslCheck(payload) {
  return {
    type: SUBMIT_DSL_CHECK,
    payload
  };
}

export function getAddressData(payload) {
  return {
    type: GET_DSL_ADDRESS_DATA,
    payload
  };
}

export function getVodafoneVoucherValidity(payload) {
  return {
    type: GET_DSL_VODAFONE_VOUCHER_VALIDITY,
    payload
  };
}

export function getDslFormData(payload) {
  return {
    type: GET_DSL_FORM_DATA,
    url: payload
  };
}

export function resetDslFormData() {
  return {
    type: RESET_DSL_FORM_DATA
  };
}

export function submitDslFormDataReset(url) {
  return {
    type: SUBMIT_DSL_FORM_DATA_RESET,
    url
  };
}

export function setDslCheckErrors(payload) {
  return {
    type: SET_DSL_CHECK_ERRORS,
    payload
  };
}

export function setDslLoader(payload) {
  return {
    type: SET_DSL_LOADER,
    payload
  };
}

export function setDslResult(payload) {
  return {
    type: SET_DSL_RESULT,
    payload
  };
}
