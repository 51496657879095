export default (array, updateArray) => updateArrayRecursive(array, updateArray);

/**
 * Updates an existing array with another array - both arrays need the exact same structure but not necessarily the same
 * elements. Only matching elements get updated!
 *
 * @param array
 * @param updateArray
 * @returns array
 */
function updateArrayRecursive(array, updateArray) {
    for (let i = 0; i < updateArray.length; i++) {
        const arrayElem = array[i];
        const updateElem = updateArray[i];

        if (isObject(updateElem)) {
            for (const prop in updateElem) {
                if (updateElem.hasOwnProperty(prop)) {
                    if (Array.isArray(updateElem[prop])) {
                        arrayElem[prop] = updateArrayRecursive(
                            arrayElem[prop],
                            updateElem[prop]
                        );
                    } else if (
                        arrayElem.hasOwnProperty(prop) &&
                        updateElem[prop] !== null
                    ) {
                        arrayElem[prop] = updateElem[prop];
                    }
                }
            }
        }
    }
    return array;
}

// Returns if a value is an object
function isObject(value) {
    return value && typeof value === "object" && value.constructor === Object;
}
