export const CLOSE_CHECKOUT_MODAL = '@@checkout/CLOSE_CHECKOUT_MODAL';
export const OPEN_CHECKOUT_MODAL = '@@checkout/OPEN_CHECKOUT_MODAL';

export const SAVE_VALUE = '@@checkout/SAVE_VALUE';
export const SAVE_NORMALIZED_VALUE = '@@checkout/SAVE_NORMALIZED_VALUE';
export const SET_VALIDATION_ERROR = '@@checkout/SET_VALIDATION_ERROR';
export const SET_CHECKOUT_ERRORS = '@@checkout/SET_CHECKOUT_ERRORS';
export const CLEAR_CHECKOUT_ERRORS = '@@checkout/CLEAR_CHECKOUT_ERRORS';
export const VALIDATE_CHECKOUT_STEP = '@@checkout/VALIDATE_CHECKOUT_STEP';
export const SET_ACTIVE_CHECKOUT_STEP = '@@checkout/SET_ACTIVE_CHECKOUT_STEP';
export const SET_VALID_CHECKOUT_STEP = '@@checkout/SET_VALID_CHECKOUT_STEP';
export const SET_EDIT_MODE_CHECKOUT = '@@checkout/SET_EDIT_MODE_CHECKOUT';
export const SUBMIT_CHECKOUT = '@@checkout/SUBMIT_CHECKOUT';
export const SET_CHECKOUT_FORM_META = '@@checkout/SET_CHECKOUT_FORM_META';
export const SEND_MAIL_TO_PROVIDER = 'SEND_MAIL_TO_PROVIDER';
export const USER_HAS_GOT_THE_PDF_FILE = "USER_HAS_GOT_THE_PDF_FILE";
export const GET_PDF_FILES_FROM_PROVIDER = "GET_PDF_FILES_FROM_PROVIDER";
export const SET_VOUCHER_CODE = "SET_VOUCHER_CODE";
export const SET_ADDRESS_VALIDATED = "SET_ADDRESS_VALIDATED";

/**
 * @param {string} checkoutDataType
 * @param {string} key
 * @param {string} value
 *
 * @returns {{type: string, checkoutDataType: string, key: string, value: string}}
 */
export function saveValue(checkoutDataType, key, value) {
  return {
    type: SAVE_VALUE,
    checkoutDataType,
    key,
    value
  };
}

/**
 * @param {string} checkoutDataType
 * @param {string} key
 * @param {string} value
 * @param {func} function
 *
 * @returns {{type: string, checkoutDataType: string, key: string, value: string}}
 */
export function saveNormalizedValue(checkoutDataType, key, value, func) {
  return {
    type: SAVE_NORMALIZED_VALUE,
    checkoutDataType,
    key,
    value,
    func
  };
}

/**
 * @param {string} checkoutErrorType
 * @param {string} key
 * @param {string} message
 *
 * @return {{type: string, checkoutErrorType: string, key: string, message: string}}
 */
export function setValidationError(checkoutErrorType, key, message) {
  return {
    type: SET_VALIDATION_ERROR,
    checkoutErrorType,
    key,
    message
  };
}

/**
 * @param {string} checkoutErrorType
 * @param {object} errors
 *
 * @return {{type: string, checkoutErrorType: string, errors: object}}
 */
export function setCheckoutErrors(checkoutErrorType, errors) {
  return {
    type: SET_CHECKOUT_ERRORS,
    checkoutErrorType,
    errors
  };
}

/**
 * @param {string} checkoutErrorType
 *
 * @return {{type: string, checkoutErrorType: string}}
 */
export function clearCheckoutErrors(checkoutErrorType, fieldName) {
  return {
    type: CLEAR_CHECKOUT_ERRORS,
    checkoutErrorType,
    fieldName
  };
}

/**
 * @param {string} modalType
 * @param {object} modalProps
 *
 * @return {{type: string, modalType: string, modalProps: ?object}}
 */
export const openCheckoutModal = (modalType, modalProps = null) => ({
  type: OPEN_CHECKOUT_MODAL,
  modalType,
  modalProps
});

/**
 * @return {{type: string}}
 */
export const closeCheckoutModal = () => ({
  type: CLOSE_CHECKOUT_MODAL
});

/**
 * @param {{checkoutData: object, nextStepNumber: number, activeStepName: string, nextStepName: string }}
 * @param {string} checkoutType
 * @param {object} checkoutData
 * @param {string} activeStepName
 * @param {number} stepNumber
 * @param {nextStepName} nextStepName
 * @param {number} nextStepNumber
 *
 * @returns {{type: string, checkoutData: object, checkoutData: object, activeStepName: string, stepNumber: number nextStepName: string, nextStepNumber: number }}
 */
export function validateCheckoutStep(
  checkoutType, checkoutData, activeStepName, stepNumber, nextStepName,
  nextStepNumber, isLastStep, isEditMode
) {
  return {
    type: VALIDATE_CHECKOUT_STEP,
    checkoutType,
    checkoutData,
    activeStepName,
    stepNumber,
    nextStepName,
    nextStepNumber,
    isLastStep,
    isEditMode
  };
}

/**
 * @param {string} checkoutLogicType
 * @param {string} checkoutStep
 *
 * @returns {{type: string, checkoutLogicType: string, checkoutStep: string}}
 */
export function setActiveCheckoutStep(checkoutLogicType, checkoutStep) {
  return {
    type: SET_ACTIVE_CHECKOUT_STEP,
    checkoutLogicType,
    checkoutStep
  };
}

/**
 * @param {string} checkoutLogicType
 * @param {boolean} isEditMode
 *
 * @returns {{type: string, checkoutLogicType: string, value: boolean}}
 */
export function setEditModeCheckout(checkoutLogicType, isEditMode) {
  return {
    type: SET_EDIT_MODE_CHECKOUT,
    checkoutLogicType,
    isEditMode
  };
}

/**
 * @param {string} checkoutLogicType
 * @param {string} checkoutStep
 * @param {boolean} value
 *
 * @returns {{type: string, checkoutLogicType: string, checkoutStep: string, value: boolean}}
 */
export function setValidCheckoutStep(checkoutLogicType, checkoutStep, value) {
  return {
    type: SET_VALID_CHECKOUT_STEP,
    checkoutLogicType,
    checkoutStep,
    value
  };
}

export const setAddressValidated = (payload) => ({
  type: SET_ADDRESS_VALIDATED,
  payload
})

export const setVoucherCode = (payload) => ({
  type: SET_VOUCHER_CODE,
  payload,
});

/**
 * @returns {{type: string}}
 */
export function submitCheckout() {
  return {
    type: SUBMIT_CHECKOUT
  };
}

export function setCheckoutFormMeta(fieldName, checkoutFormMeta, payload) {
  return {
    type: SET_CHECKOUT_FORM_META,
    checkoutFormMeta,
    fieldName,
    payload
  };
}
