export function init() {
    return (dispatch) => {
        dispatch({
            type: "INIT_OVERVIEW_MODEL",
        });
    };
}

export function toggleFilterMenu() {
    return {
        type: "TOGGLE_FILTER_MENU",
    };
}

export function changeMobileFilterCategory(category) {
    return {
        type: "CHANGE_MOBILE_FILTER_CATEGORY",
        data: { category },
    };
}

export function addFilter(group, value) {
    return {
        type: "ADD_FILTER",
        data: { group, value },
    };
}

export function removeFilter(group, value) {
    return {
        type: "REMOVE_FILTER",
        data: { group, value },
    };
}

export function deleteFilters() {
    return {
        type: "DELETE_FILTERS",
        data: { filters: [] },
    };
}

export function updateFilter(group, value) {
    return {
        type: "UPDATE_FILTER",
        data: { group, value },
    };
}

export function setHistoryState(stateObj) {
    return {
        type: "SET_HISTORY_STATE",
        data: stateObj,
    };
}

export function addnFilterCandidate(group, value) {
    return {
        type: "ADD_FILTER_CANDIDATE",
        data: { group, value },
    };
}

export function addnFilterRemoveCandidate(group, value) {
    return {
        type: "ADD_FILTERREMOVE_CANDIDATE",
        data: { group, value },
    };
}

export function removenFilterCandidate(group, value) {
    return {
        type: "REMOVE_FILTERCANDIDATE",
        data: { group, value },
    };
}

export function removenFilterRemoveCandidate(group, value) {
    return {
        type: "REMOVE_FILTERCANDIDATE2REMOVE",
        data: { group, value },
    };
}

export function removeTooltip() {
    return {
        type: "REMOVE_TOOLTIP",
    };
}

export function removeInfobox() {
    return {
        type: "REMOVE_INFOBOX",
    };
}

export function setRichSeoDataForTariffOverview(data) {
    return {
        type: "SET_RICH_SEO_DATA_FOR_TARIFF_OVERVIEW",
        data,
    };
}

export function getRichSeoDataForTariffOverview(data) {
    return {
        type: "GET_RICH_SEO_DATA_FOR_TARIFF_OVERVIEW",
        data,
    };
}
