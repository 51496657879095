import { omit } from 'lodash';

export const initialState = {
  teaserlists: null,
  offerlist: []
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'ADD_PRODUCT':
      return {
        ...state,
        [action.data.productId]: { ...omit(action.data.product, 'id') }
      };
    default:
      return state;
  }
};

export default reducer;
