export const INC_PORTING_NUMBER_COUNT = '@@checkout/INC_PORTING_NUMBER_COUNT';
export const SET_CUSTOMER_EXISTING = '@@checkout/SET_CUSTOMER_EXISTING';
/**
 * @param {string} params
 * @param {function} callback
 *
 * @returns {{type: string, callback: function}}
 */
export function incNumberPortingCount(checkoutDataType) {
  return {
    type: INC_PORTING_NUMBER_COUNT,
    checkoutDataType
  };
}

export function setCustomerExisting(payload) {
  return {
      type: SET_CUSTOMER_EXISTING,
      payload,
  };
}
