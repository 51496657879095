import { all } from 'redux-saga/effects';
import { checkoutSaga } from './modules/Checkout/sagas';
import { dslCheckSaga } from './landingpages/templates/dsl/FormWidget/sagas';
import { dslCheckoutAddressSaga } from './modules/Checkout/DSLCheckout/DSLCheckoutAddressStep/sagas';
import { dslCheckoutPaymentSaga } from './modules/Checkout/DSLCheckout/DSLCheckoutPaymentStep/sagas';
import { energyCheckSaga } from './landingpages/templates/StromGas/FormWidget/sagas';
import { energyCheckoutSaga } from './modules/Checkout/EnergyCheckout/EnergyCheckoutPaymentStep/sagas';
import { getRichSeoSagaOverview } from './overview/sagas';
import { getRichSeoSagaTariffOverview } from './tariffoverview/sagas';

export default function* rootSaga() {
  yield all([
    ...checkoutSaga,
    ...dslCheckSaga,
    ...dslCheckoutAddressSaga,
    ...dslCheckoutPaymentSaga,
    ...energyCheckSaga,
    ...energyCheckoutSaga,
    ...getRichSeoSagaOverview,
    ...getRichSeoSagaTariffOverview
  ]);
}
