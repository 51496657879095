import Axios from 'axios';
import { put, takeEvery } from 'redux-saga/effects';

import {
  SUBMIT_ENERGY_CHECK,
  SUBMIT_ZIP_CHECK,
  setEnergyCheckErrors,
  setEnergyLoader,
  setEnergyResult,
  setEnergyPlaces,
  setEnergyCheck
} from './actions';

import {
  ENERGY_RESULT_SUCCESS,
  ENERGY_RESULT_ERROR
} from './constants';

import {
  setOffers
} from '@core/frontend/src/actions/landingpages';
import scrollSmoothlyToElement from '../../../../global/helper/scrollSmoothlyToElement';

const options = {
  timeout: 25000,
  offerUrl: '/helper/get-energy-data/',
  placesUrl: '/helper/energy/get-places/'
};

// Workers
export function* submitEnergyCheck(action) {
  const {
    payload
  } = action;

  try {
    yield put(setEnergyLoader(true));
    const energyTypeUrl = window.location.pathname.split('/')[1];

    Object.assign(payload, {
      url: energyTypeUrl,
      energyType: payload.energyType
    });

    const { consumption, zip: postalCode, energyType, place, energyVoucher } = payload;

    const productType = energyType === '1' ? 'strom' : 'gas';

    const offerData = yield Axios({
      url: `${REST_URL}/products?productType=${productType}&postalCode=${postalCode}&place=${place}&consumption=${consumption}&promoCode=${energyVoucher}&environment=${ENVIRONMENT}&tenant=${TENANT?.toLowerCase()}`,
      timeout: options.timeout,
    });
    yield put(setEnergyLoader(false));
    yield put(setEnergyResult(ENERGY_RESULT_SUCCESS));
    window.dataLayer.push({
      event: 'calcconfirm',
      eventCategory: 'Form',
      eventAction: 'submit',
      eventLabel: 'calculationconfirm',
      producttype: productType,
      // 'persons': '[Anzahl der gewählten Personen]',
      volume: payload.consumption,
      plz: payload.zip
    });

    yield put(setOffers(offerData.data.payload));

    const checkEnergyButton = document.getElementsByClassName('checkEnergyButton')[0];
    scrollSmoothlyToElement(checkEnergyButton, -10);
  } catch (error) {
    yield put(setOffers([]));
    if (error.response && error.response.status === 400) {
      const errorsObj = error.response.data?.errors
      yield put(setEnergyCheckErrors(errorsObj || error.response.data));
    } else {
      yield put(setEnergyResult(ENERGY_RESULT_ERROR));
    }
    yield put(setEnergyLoader(false));
  }
}

export function* submitZipCheck(action) {
  try {
    const placesData = yield Axios({
      method: 'get',
      url: `${options.placesUrl}${action.payload.zip}/`,
      timeout: options.timeout
    });
    const places = placesData.data.places;

    if (places.length === 1) {
      yield put(setEnergyCheck({
        place: places[0]
      }));
    } else {
      yield put(setEnergyPlaces(placesData.data.places));
    }
  } catch (error) {
    console.log(error);
  }
}

// Watchers
export function* waitForEnergyCheck() {
  yield takeEvery(SUBMIT_ENERGY_CHECK, submitEnergyCheck);
}

export function* waitForZipCheck() {
  yield takeEvery(SUBMIT_ZIP_CHECK, submitZipCheck);
}

export const energyCheckSaga = [
  waitForEnergyCheck(),
  waitForZipCheck(),
];
