import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { isXs } from '@core/frontend/src/support/page-utils';
import Item from './Item';

class Breadcrumb extends React.Component {
  static propTypes = {
    onPageInit: PropTypes.func,
    items: PropTypes.array.isRequired
  };

  render() {
    const mt = this.props.browser.mediaType;
    let lastCategory = null;

    if (this.props.items.length > 1) {
      lastCategory = this.props.items[this.props.items.length - 2];
    }

    if (isXs(mt) && !lastCategory) {
      return null;
    }

    return (
      <div id="breadcrumb">
        <div className="wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs">
                <ol className="breadcrumb">
                  {!isXs(mt) ? (
                    <Item
                      active={false}
                      text="Startseite"
                      url="/"
                      position="1"
                    >
                      <ReactSVG
                        src={`${window.CDN_URL}/img/mm-neu/home.svg`}
                        wrapper="span"
                        className="home"
                      />
                    </Item>
                  ) : null}

                  {!isXs(mt) ? (
                    this.props.items.map((item, key) => (
                      <Item
                        active={item.active}
                        text={item.text}
                        url={item.url}
                        position={key + 2}
                        key={key}
                      />
                    ))
                  ) : null}

                  {isXs(mt) ? (
                    <Item
                      active={false}
                      text={lastCategory.text}
                      url={lastCategory.url}
                      position="1"
                    >
                      <i className="icon-MM_iconfont_ArrowL" />

                      {lastCategory.text}
                    </Item>
                  ) : null}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  browser: state.page.browser
});

export default connect(mapStateToProps)(Breadcrumb);
