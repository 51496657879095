const SET_RETRIES = "SET_RETRIES";

export function initPage() {
    return (dispatch) => {
        dispatch(setPageDefaults());
    };
}

export function changeDevice(data) {
    return (dispatch) => {
        dispatch({
            type: "CHANGE_DEVICE",
        });
    };
}

function setPageDefaults() {
    return (dispatch) => {
        dispatch({
            type: "INIT_PAGE_MODEL",
        });
    };
}

export const setRetries = (count) => ({
    type: SET_RETRIES,
    payload: count,
});

export function calculateResponsiveState({ matchMedia } = {}) {
    return {
        type: "CALCULATE_RESPONSIVE_STATE",
        matchMedia,
    };
}
